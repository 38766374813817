import React, { Component } from 'react';
import classNames from 'classnames';

import PLUPageStore from 'stores/Settings/PLUPageStore';
import { isBeforeDate } from 'helpers/datetime';

import style from './PLUPlanner.module.scss';
import PLUPlannerWeekday from './PLUPlannerWeekday';
import PLUPlannerTimeline from './PLUPlannerTimeline';

interface Props {
  cell: string;
  store: PLUPageStore;
}

class PLUPlannerRow extends Component<Props> {
  render() {
    const { cell, store } = this.props;

    return (
      <div className={style.tr}>
        <div className={classNames(style.td, style.td__primary)}>
          <div className={style.line__wrapper}>
            <div className={style.line__cell}>{cell}</div>
          </div>
        </div>

        <div className={classNames(style.td, style.td__primary)}>
          <div className={style.line__wrapper}>
            <PLUPlannerTimeline />
          </div>
        </div>

        {store.plannerStore.periodDates.map(date => (
          <PLUPlannerWeekday
            key={date}
            date={date}
            items={this._getItemsForDate(date)}
            disabled={this._isDayDisabled(date)}
            store={store}
          />
        ))}
      </div>
    );
  }

  private _getItemsForDate = (date: string) => {
    const { store, cell } = this.props;

    return store.plannerStore.pluItems[cell]
      .filter(item => item.dates.includes(date))
      .sort((a, b) => {
        const isStandard = a.from.startsWith('00:00') && a.to.endsWith('23:59');
        if (isStandard) return -1;

        const minutesFromA = this._getTimeRangeMinutesSum(a.from);
        const minutesFromB = this._getTimeRangeMinutesSum(b.from);

        const isBefore = minutesFromA < minutesFromB;
        if (isBefore) return -1;

        return 0;
      });
  };

  private _isDayDisabled = (date: string) => {
    return (
      !this.props.store.isCopyModeEnabled &&
      isBeforeDate(new Date(date), new Date())
    );
  };

  private _getTimeRangeMinutesSum = (range: string) => {
    const [hours, minutes] = range.split(':');

    return +hours * 60 + +minutes;
  };
}

export default PLUPlannerRow;
