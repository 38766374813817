import { action, computed, observable } from 'mobx';
import { DateTime } from 'luxon';

import { getDatesFromPeriod, getLocalDateTime } from 'helpers/datetime';
import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';
import { PLUCopyModeType } from 'stores/Settings/PLUPageStore';
import httpFacade from 'http/httpFacade';
import {
  CreateUpdatePLUItemDto,
  PLUItemComponent,
} from 'http/Api/PLUItems/types';
import { getPLUItemComponentOnCopy } from './helpers';

export class CopyPLUFromDayForm {
  readonly copyMode = PLUCopyModeType.Day;

  @observable public from = getLocalDateTime();
  @observable public periodTo: [DateTime, DateTime] = [
    getLocalDateTime().plus({ day: 1 }),
    getLocalDateTime().plus({ day: 1 }),
  ];
  @observable public selectedDays: string[] = [];
  @observable public component: PLUItemComponent;

  private _plannerStore: PLUPlannerStore;

  constructor(plannerStore: PLUPlannerStore, component?: PLUItemComponent) {
    this._plannerStore = plannerStore;
    this.component = component ?? PLUItemComponent.Spt;
  }

  @action
  public setDateFrom = (date: DateTime) => {
    this.from = date;
  };

  @action
  public changeDateFrom = (date: DateTime) => {
    this.from = date;
    this._plannerStore.changeDate([date]);
  };

  @action
  public changePeriodTo = ([from, to]: DateTime[]) => {
    this.periodTo = [from, to];
  };

  @action
  public changeSelectedDays = (days: string[]) => {
    this.selectedDays = days;
  };

  @action
  public changeComponent = (component: PLUItemComponent | null) => {
    if (!component) return;

    this.component = component;
  };

  @action
  public reset = () => {
    this.selectedDays = [];
  };

  public copy = async () => {
    const date = this.from.toISODate();
    const { data: items } = await this._plannerStore.promiseService.add(() =>
      httpFacade.pluItems.fetchPLUItemsByPeriod(date, date),
    );

    const newItems: CreateUpdatePLUItemDto[] = [];

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      for (const item of items[cell]) {
        const { alias, from, component, to, menuItemId } = item;

        if (
          this.component !== PLUItemComponent.All &&
          component !== PLUItemComponent.All &&
          component !== this.component
        ) {
          continue;
        }

        newItems.push({
          cell: +cell,
          alias,
          component: getPLUItemComponentOnCopy(component, this.component),
          from,
          to,
          menuItemUUID: menuItemId,
          dates: this.periodToDates,
        });
      }
    }

    for (const item of newItems) {
      this._plannerStore.promiseService.add(() =>
        httpFacade.pluItems.createPLUItem(item).then(res => {
          this._plannerStore.addToPLUItems(res.data);
        }),
      );
    }
  };

  @computed
  public get periodToDates() {
    return getDatesFromPeriod(this.periodTo, this.selectedDays);
  }

  @computed
  public get isCopyDisabled() {
    return !this.periodToDates.length;
  }
}
