import { DateTime } from 'luxon';
import { observable, computed } from 'mobx';

import { getDate, getLocalDateTime } from 'helpers/datetime';
import { BaseFormModel, isRequired } from 'stores/BaseForm';
import { StocklistReportReqParams } from 'stores/Report/types';

class StocklistReportForm extends BaseFormModel {
  @observable
  @isRequired()
  period: [DateTime, DateTime] = [getLocalDateTime(), getLocalDateTime()];

  @computed
  get requestParams(): StocklistReportReqParams {
    const [from, to] = this.period;

    return {
      from: getDate(from.toJSDate()),
      to: getDate(to.toJSDate()),
    };
  }
}

export default StocklistReportForm;
