import React, { FC } from 'react';
import classNames from 'classnames';

import style from './Menu.module.scss';

import { MenuItem } from 'stores/Menu/types';

import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';
import Icon from 'components/Icon/Icon';

interface Props {
  item: MenuItem;
  onClick?: () => void;
}

const DayItem: FC<Props> = ({ item, onClick }) => (
  <li
    key={item.id}
    className={classNames(
      style.day__item,
      onClick && style.day__item__clickable,
    )}
    onClick={onClick}
  >
    <p>
      {item.title}
      {(item.initStock ?? 0) > 0 && (
        <span className={style.day__item__amount}>x{item.initStock}</span>
      )}
      {typeof item.fixedAmount === 'boolean' && !item.fixedAmount && (
        <Icon
          className={style.day__item__notFixedAmount}
          type="stocklistChangeAmount"
        />
      )}
    </p>
    <p>
      <FormattedCurrency value={item.price} />
    </p>
  </li>
);

export default DayItem;
