import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

import {
  IStocklistReport,
  StocklistReportReqParams,
} from 'stores/Report/types';
import { StocklistItem } from './types';

export const stocklistApi = {
  fetchStocklist(date: string) {
    return http.get<StocklistItem[]>(api(URLS.stocklist.list), {
      replacePathParams: { date },
    });
  },

  fetchReport({ from, to }: StocklistReportReqParams) {
    return http.get<IStocklistReport[]>(api(URLS.stocklist.report), {
      replacePathParams: { from, to },
    });
  },

  fetchReportPdf(params: StocklistReportReqParams) {
    return http.get<Blob>(api(URLS.stocklist.reportPdf), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  fetchReportCsv(params: StocklistReportReqParams) {
    return http.get<Blob>(api(URLS.stocklist.reportCsv), {
      params,
      headers: {
        Accept: '*/*',
      },
      responseType: 'blob',
    });
  },

  addAdditionalAmount(id: string, additionalValue: string) {
    return http.put(api(URLS.stocklist.additionalAmount), null, {
      replacePathParams: { id },
      params: { additionalValue },
    });
  },

  decreaseAmount(id: string, decreaseValue: string, description: string) {
    return http.put(api(URLS.stocklist.decreaseAmount), null, {
      replacePathParams: { id },
      params: { decreaseValue, description },
    });
  },
};
