import { action, computed, observable } from 'mobx';
import { DateTime } from 'luxon';

import { getDatesFromPeriod, getLocalDateTime } from 'helpers/datetime';
import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';
import { PLUCopyModeType } from 'stores/Settings/PLUPageStore';
import httpFacade from 'http/httpFacade';
import {
  CreateUpdatePLUItemDto,
  PLUItemComponent,
} from 'http/Api/PLUItems/types';
import { getPLUItemComponentOnCopy } from './helpers';

export class CopyPLUFromWeekForm {
  readonly copyMode = PLUCopyModeType.Week;

  @observable public periodFrom: [DateTime, DateTime] = [
    getLocalDateTime().startOf('week'),
    getLocalDateTime().endOf('week'),
  ];
  @observable public periodTo: [DateTime, DateTime] = [
    getLocalDateTime()
      .plus({ week: 1 })
      .startOf('week'),
    getLocalDateTime()
      .plus({ week: 1 })
      .endOf('week'),
  ];
  @observable public component: PLUItemComponent;

  private _plannerStore: PLUPlannerStore;

  constructor(plannerStore: PLUPlannerStore, component?: PLUItemComponent) {
    this._plannerStore = plannerStore;
    this.component = component ?? PLUItemComponent.Spt;
  }

  @action
  public setPeriodFrom = (date: DateTime) => {
    this.periodFrom = [date.startOf('week'), date.endOf('week')];
  };

  @action
  public changePeriodFrom = ([date]: DateTime[]) => {
    this.periodFrom = [date.startOf('week'), date.endOf('week')];
    this._plannerStore.changeDate([date]);
  };

  @action
  public changePeriodTo = ([from, to]: DateTime[]) => {
    this.periodTo = [from.startOf('week'), to.endOf('week')];
  };

  @action
  public changeComponent = (component: PLUItemComponent | null) => {
    if (!component) return;

    this.component = component;
  };

  public copy = async () => {
    const fromDate = this.periodFrom[0].toISODate();
    const toDate = this.periodFrom[1].toISODate();

    const { data: items } = await this._plannerStore.promiseService.add(() =>
      httpFacade.pluItems.fetchPLUItemsByPeriod(fromDate, toDate),
    );

    const periodToDates = getDatesFromPeriod(this.periodTo);
    const newItems: CreateUpdatePLUItemDto[] = [];

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      for (const item of items[cell]) {
        const { alias, from, to, component, dates, menuItemId } = item;

        if (
          this.component !== PLUItemComponent.All &&
          component !== PLUItemComponent.All &&
          component !== this.component
        ) {
          continue;
        }

        newItems.push({
          cell: +cell,
          alias,
          component: getPLUItemComponentOnCopy(component, this.component),
          from,
          to,
          menuItemUUID: menuItemId,
          dates: this._calculatePeriodDatesByItemWeekdays(dates, periodToDates),
        });
      }
    }

    for (const item of newItems) {
      this._plannerStore.promiseService.add(() =>
        httpFacade.pluItems.createPLUItem(item),
      );
    }
  };

  private _calculatePeriodDatesByItemWeekdays = (
    itemPeriodDates: string[],
    periodDates: string[],
  ) => {
    const newPeriod: string[] = [];

    for (const itemDate of itemPeriodDates) {
      const itemDateWeekday = new Date(itemDate).getDay();

      for (const date of periodDates) {
        const dateWeekday = new Date(date).getDay();

        if (itemDateWeekday === dateWeekday) {
          newPeriod.push(date);
        }
      }
    }

    return newPeriod;
  };

  @computed
  public get isCopyDisabled() {
    return false;
  }
}
