import { action, observable } from 'mobx';

import httpFacade from 'http/httpFacade';
import Log from 'helpers/log';
import { downloadPdfBlob } from 'helpers/pdf';
import { downloadCsvBlob } from 'helpers/csv';
import { showDate } from 'helpers/datetime';
import RootStore from 'stores/RootStore';

import {
  IStocklistReport,
  SortProperty,
  StocklistReportReqParams,
} from './types';

class StocklistReportStore {
  @observable report: IStocklistReport[];
  @observable loading = false;
  sortProperty: SortProperty | null = null;

  @action
  getReport = async (params: StocklistReportReqParams) => {
    try {
      this.loading = true;

      const { data } = await httpFacade.stocklist.fetchReport(params);

      this.report = data;
    } catch (error) {
      Log.warn(error);
    } finally {
      this.loading = false;
    }
  };

  @action
  getPdfReport = async (params: StocklistReportReqParams) => {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.stocklist.pdf',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.stocklist.fetchReportPdf(params);
      const pdfBlobURL = URL.createObjectURL(data);
      const pdfBlob = new Blob([data], { type: 'application/pdf' });

      downloadPdfBlob({ fileName, pdfBlob, pdfBlobURL });
    } catch (error) {
      Log.info(error);
    }
  };

  @action
  getCsvReport = async (params: StocklistReportReqParams) => {
    try {
      const fileName = RootStore.localization.formatMessage(
        'filename.report.stocklist.csv',
        {
          range:
            params.from !== params.to
              ? `${showDate(params.from)} - ${showDate(params.to)}`
              : showDate(params.from),
        },
      );

      const { data } = await httpFacade.stocklist.fetchReportCsv(params);
      const csvBlobURL = URL.createObjectURL(data);
      const csvBlob = new Blob([data], { type: 'text/csv' });

      downloadCsvBlob({ fileName, csvBlob, csvBlobURL });
    } catch (error) {
      Log.info(error);
    }
  };

  @action.bound
  changeSortProperty(sortProperty: SortProperty | null) {
    this.sortProperty = sortProperty;
  }
}

export default StocklistReportStore;
