import React, { Component } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import style from './SelectField.module.scss';
import RootStore from '../../../../stores/RootStore';

export interface Option<T> {
  value: T;
  label: string | JSX.Element;
  disabled?: boolean;
}

export interface SelectFieldProps<T> {
  className?: string;
  invalid?: boolean;
  classNamePrefix?: string;
  value: T | T[];
  onChange: (v: T | null, option: Option<T> | null) => void;
  options: Array<Option<T>>;
  disabled?: boolean;
  placeholder?: string;
  components?: any;
  isMulti?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

class SelectField<T = any> extends Component<SelectFieldProps<T>> {
  value = () => {
    const { value, options, isMulti } = this.props;
    if (isMulti && Array.isArray(value)) {
      return options.filter(option => (value as T[]).includes(option.value));
    }
    return options.find(option => option.value === value) ?? null;
  };

  onChange = (selectedOption: Option<T> | Array<Option<T>> | null) => {
    if (Array.isArray(selectedOption)) {
      // Handle multi-select case
      const values = selectedOption.map(option => option.value);
      this.props.onChange(values as any, selectedOption as any);
    } else {
      // Handle single-select case
      this.props.onChange(
        selectedOption ? selectedOption.value : null,
        selectedOption || null,
      );
    }
  };

  isOptionDisabled = (option: Option<T>) => !!option.disabled;

  render() {
    const {
      value,
      onChange,
      disabled,
      className,
      invalid,
      components,
      placeholder,
      isMulti,
      ...props
    } = this.props;

    return (
      <ReactSelect
        {...props}
        isMulti={isMulti}
        classNamePrefix="select"
        className={classNames(className, style.select, {
          [style.select__invalid]: invalid,
        })}
        styles={{
          menu: (provided, _state) => ({
            ...provided,
            zIndex: 15,
          }),
          control: (provided, _state) => ({
            ...provided,
          }),
        }}
        isOptionDisabled={this.isOptionDisabled}
        isDisabled={disabled}
        onChange={this.onChange}
        components={{ IndicatorSeparator: () => null, ...components }}
        value={this.value()}
        noOptionsMessage={() =>
          RootStore.localization.formatMessage('select.noOptions')
        }
        placeholder={
          placeholder ||
          RootStore.localization.formatMessage('select.placeholder')
        }
      />
    );
  }
}

export default SelectField;
