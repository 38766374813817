import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';

import { PLUItem, PLUItemComponent } from 'http/Api/PLUItems/types';
import { getLocalDateTime, isBeforeDate } from 'helpers/datetime';
import httpFacade from 'http/httpFacade';
import { MenuItem } from 'stores/Menu/types';
import { PromiseService } from 'services/PromiseService';

// week
const PERIOD = 7;
export enum PLUPlannerTab {
  Spt,
  Consumer,
}

class PLUPlannerStore {
  @observable public date = getLocalDateTime();
  @observable public tab = PLUPlannerTab.Spt;
  @observable public menuItems: MenuItem[] = [];
  @observable public promiseService = new PromiseService();

  @observable private _pluItems: { [cell: string]: PLUItem[] } = {};

  @action
  public init = async () => {
    const [menuItems] = await Promise.all([
      httpFacade.menuItems.fetchMenuItems(),
      this.fetchCurrentPeriod(),
    ]);

    this.menuItems = menuItems.data;
  };

  @action
  public fetchCurrentPeriod = async () => {
    const [items] = await this.promiseService.add(() =>
      Promise.all([
        httpFacade.pluItems.fetchPLUItemsByPeriod(
          this.weekStartDate.toISODate(),
          this.weekEndDate.toISODate(),
        ),
      ]),
    );

    this._pluItems = items?.data;
  };

  @action
  public toPrevWeek = () => {
    this.changeDate([this.date.minus({ week: 1 })]);
  };

  @action
  public toNextWeek = () => {
    this.changeDate([this.date.plus({ week: 1 })]);
  };

  @action
  public changeDate = ([date]: DateTime[]) => {
    let isSameWeek = false;
    if (this.date.weekNumber === date.weekNumber) {
      isSameWeek = true;
    }

    this.date = date;

    if (!isSameWeek) {
      this.fetchCurrentPeriod();
    }
  };

  @action
  public changeTab = (tab: PLUPlannerTab) => {
    this.tab = tab;
  };

  public getItemTitle = (pluItem: PLUItem) => {
    const { alias, pluId, menuItemId } = pluItem;

    if (alias) return alias;

    const menuItem = this.menuItems.find(item => item.id === menuItemId);
    if (menuItem) return menuItem.title;

    return pluId;
  };

  @action
  public deleteItem = async (id: string) => {
    await this.promiseService.add(() => httpFacade.pluItems.deletePLUItem(id));

    const items = JSON.parse(JSON.stringify(this._pluItems)) as {
      [cell: string]: PLUItem[];
    };

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      items[cell] = items[cell].filter(item => item.pluId !== id);
    }

    this._pluItems = items;
  };

  @action
  public addToPLUItems = (item: PLUItem) => {
    this._pluItems[item.cell].push(item);
  };

  @computed
  public get pluItems() {
    const items = JSON.parse(JSON.stringify(this._pluItems)) as {
      [cell: string]: PLUItem[];
    };

    for (const cell in items) {
      if (!items.hasOwnProperty(cell)) continue;

      items[cell] = items[cell].filter(item => {
        if (item.component === PLUItemComponent.All) return true;

        switch (this.tab) {
          case PLUPlannerTab.Spt: {
            return item.component === PLUItemComponent.Spt;
          }
          case PLUPlannerTab.Consumer: {
            return item.component === PLUItemComponent.Consumer;
          }
          default:
            return false;
        }
      });
    }

    return items;
  }

  @computed
  public get loading() {
    return this.promiseService.isPending;
  }

  @computed
  public get weekStartDate() {
    return this.date.startOf('week');
  }

  @computed
  public get weekEndDate() {
    return this.date.endOf('week');
  }

  @computed
  public get weekNumber() {
    return this.date.weekNumber;
  }

  @computed
  public get periodDates(): string[] {
    const dates: string[] = [];

    for (
      let day = this.weekStartDate;
      day.weekday <= PERIOD && dates.length < PERIOD;
      day = day.plus({ day: 1 })
    ) {
      dates.push(day.toISODate());
    }

    return dates;
  }

  @computed
  public get availableWeekDays(): string[] {
    return this.periodDates.filter(period => period >= this.date.toISODate());
  }

  @computed
  public get isWeekDisabled() {
    return isBeforeDate(this.weekEndDate.toJSDate(), new Date());
  }
}

export default PLUPlannerStore;
