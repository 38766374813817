import { action, computed, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';

import httpFacade from 'http/httpFacade';
import { IntervalFetch } from 'http/intervalFetch';
import { StocklistItem, StocklistItemExtended } from 'http/Api/Stocklist/types';
import { getDate, getLocalDateTime, isBeforeDate } from 'helpers/datetime';
import { PromiseService } from 'services/PromiseService';
import { SwipeDirection } from 'stores/UserProfile/types';
import Log from 'helpers/log';

class StocklistStore {
  @observable public searchValue: string = '';
  @observable public activeDate: DateTime = getLocalDateTime();
  @observable public intervalFetch: IntervalFetch = new IntervalFetch(10000);

  @observable private _stocklistData: StocklistItem[] = [];
  @observable private _promiseService = new PromiseService();

  constructor() {
    this.intervalFetch.setRequest(this.fetchStocklist);
    reaction(
      () => this.activeDate,
      async () => {
        await this._promiseService.add(this.fetchStocklist);
      },
    );
  }

  @action
  public init = async () => {
    try {
      await this._promiseService.add(() =>
        Promise.all([this.intervalFetch.start()]),
      );
    } catch (error) {
      Log.warn(error);
    }
  };

  @action
  public fetchStocklist = async () => {
    try {
      const date = getDate(this.activeDate.toJSDate());
      const { data } = await httpFacade.stocklist.fetchStocklist(date);

      this._stocklistData = data;
    } catch (error) {
      Log.warn(error);
    }
  };

  @action
  public changeSearch = (value: string) => {
    this.searchValue = value;
  };

  @action
  public changeDate = (date: DateTime | DateTime[]) => {
    if (Array.isArray(date)) return;

    this.activeDate = date;
  };

  @action
  public swipeDay = (value: SwipeDirection) => {
    this.activeDate =
      value === 'previous'
        ? this.activeDate.minus({ day: 1 })
        : this.activeDate.plus({ day: 1 });
  };

  @action
  public clearInterval = () => {
    this.intervalFetch.stop();
  };

  @computed
  public get stocklistData(): StocklistItemExtended[] {
    const search = this.searchValue.toLowerCase();
    const filteredData = this._stocklistData.filter(item => {
      const title = item.title.toLowerCase();

      return title.includes(search);
    });

    return filteredData.map(item => {
      const newItem: StocklistItemExtended = {
        ...item,
        isSoldOut: item.currentAmount <= 0,
      };

      return newItem;
    });
  }

  @computed
  public get isAdditionalAmountDisabled() {
    return isBeforeDate(this.activeDate.toJSDate(), new Date());
  }

  @computed
  public get loading() {
    return this._promiseService.isPending;
  }
}

export default StocklistStore;
