import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import ModalStore, { DialogActionType, ModalProps } from 'stores/ModalStore';
import StocklistAddAmountModalStore, {
  StocklistAddAmountModalStep,
} from 'stores/Stocklist/StocklistAddAmountModalStore';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';
import { FormControl } from 'components/Form/FormControl/FormControl';
import ModalHeader from 'components/Modals/Components/ModalHeader/ModalHeader';
import ModalBody from 'components/Modals/Components/ModalBody/ModalBody';
import ModalControls from 'components/Modals/Components/ModalControls/ModalControls';

import style from './StocklistAddAmountModal.module.scss';

export interface StocklistAddAmountModalPayload {
  amount: number;
  isAdditionalAmountEnabled: boolean;
}

export interface StocklistAddAmountModalProps extends ModalProps {
  onChange?: (amount: number, isAdditionalAmountEnabled: boolean) => void;
}

@observer
class StocklistAddAmountModal extends Component<StocklistAddAmountModalProps> {
  private _store = new StocklistAddAmountModalStore();

  render() {
    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <ModalHeader>
          <FormattedMessage id="modal.stocklist.addAmount.title" />
        </ModalHeader>

        <ModalBody>{this._renderStepContent()}</ModalBody>

        <ModalControls>{this._renderControls()}</ModalControls>
      </Modal>
    );
  }

  private _renderStepContent = () => {
    switch (this._store.step) {
      case StocklistAddAmountModalStep.Confirm:
        return <FormattedMessage id="modal.stocklist.addAmount.confirmText" />;
      case StocklistAddAmountModalStep.Amount:
        return (
          <div>
            <FormattedMessage id="modal.stocklist.addAmount.amountText" />

            <div className={style.form__field}>
              <FormControl
                form={this._store.form}
                name="amount"
                render={propsField => <TextField {...propsField} />}
              />
            </div>
          </div>
        );
      case StocklistAddAmountModalStep.AdditionalProductionConfirm:
        return (
          <FormattedMessage id="modal.stocklist.addAmount.additionalAmountText" />
        );
      default:
        return null;
    }
  };

  private _renderControls = () => {
    switch (this._store.step) {
      case StocklistAddAmountModalStep.Confirm:
        return (
          <>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this._handleClose}
            >
              <FormattedMessage id="NO" />
            </Button>
            <Button
              className={style.btn}
              styleType="primary"
              onClick={this._store.goNextStep}
            >
              <FormattedMessage id="YES" />
            </Button>
          </>
        );

      case StocklistAddAmountModalStep.Amount:
        return (
          <>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this._handleClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={style.btn}
              styleType="primary"
              disabled={this._store.form.invalidFields.length}
              onClick={this._store.goNextStep}
            >
              <FormattedMessage id="button.saveChanges" />
            </Button>
          </>
        );

      case StocklistAddAmountModalStep.AdditionalProductionConfirm:
        return (
          <>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={() => {
                const amount = +this._store.form.amount;
                const isAdditionalAmountEnabled = false;

                this._handleSubmit({ amount, isAdditionalAmountEnabled });
              }}
            >
              <FormattedMessage id="NO" />
            </Button>

            <Button
              className={style.btn}
              styleType="primary"
              onClick={() => {
                const amount = +this._store.form.amount;
                const isAdditionalAmountEnabled = true;

                this._handleSubmit({ amount, isAdditionalAmountEnabled });
              }}
            >
              <FormattedMessage id="YES" />
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  private _handleClose = () => {
    ModalStore.closeModal(DialogActionType.submit);
  };

  private _handleSubmit = (payload: StocklistAddAmountModalPayload) => {
    this.props.onChange?.(payload.amount, payload.isAdditionalAmountEnabled);

    ModalStore.closeModal<StocklistAddAmountModalPayload>(
      DialogActionType.submit,
      payload,
    );
  };
}

export default StocklistAddAmountModal;
