import { observable } from 'mobx';

import { BaseFormModel, isNumberMore, isRequired } from '../../BaseForm';

class StocklistAddAmountModalForm extends BaseFormModel {
  @observable
  @isRequired()
  @isNumberMore(0)
  amount = '';
}

export default StocklistAddAmountModalForm;
