import React from 'react';
import { Column } from 'react-table';

import { StocklistItemExtended } from 'http/Api/Stocklist/types';

import { withArrow } from 'components/Table/helpers';
import Icon from 'components/Icon/Icon';

import style from './Stocklist.module.scss';
import Button from 'components/Button/Button';
import classNames from 'classnames';

interface Options {
  isAdditionalAmountDisabled: boolean;
  onAdditionalAmountClick: (id: string) => void;
  onDecreaseAmountClick: (id: string, additionalAmount: number) => void;
}

const config = ({
  isAdditionalAmountDisabled,
  onAdditionalAmountClick,
  onDecreaseAmountClick,
}: Options): Column[] => [
  {
    Header: withArrow('title.stocklist.article'),
    accessor: 'title',
    resizable: true,
    minWidth: 200,
    Cell: cell => {
      const item = cell.original as StocklistItemExtended;

      return (
        <div className={style.cell__item}>
          <div className={style.cell__item__text}>{item.title}</div>
          {!item.fixedAmount && (
            <Button
              styleType="text"
              className={classNames(
                style.cell__item__button,
                style.cell__item__button__primary,
              )}
              disabled={isAdditionalAmountDisabled}
              onClick={() => onAdditionalAmountClick(item.item)}
            >
              <Icon type="stocklistChangeAmount" />
            </Button>
          )}
        </div>
      );
    },
  },
  {
    Header: withArrow('title.stocklist.fullAmount'),
    accessor: 'fullAmount',
    resizable: true,
    minWidth: 110,
    Cell: cell => <div>{cell.value}</div>,
  },
  {
    Header: withArrow('title.stocklist.additionalAmount'),
    accessor: 'additionalAmount',
    resizable: true,
    minWidth: 110,
    Cell: cell => {
      const item = cell.original as StocklistItemExtended;

      return (
        <div className={style.cell__item}>
          <div className={style.cell__item__text}>{item.additionalAmount}</div>
          {!item.fixedAmount && !!item.additionalAmount && (
            <Button
              styleType="text"
              className={classNames(
                style.cell__item__button,
                style.cell__item__button__secondary,
              )}
              disabled={isAdditionalAmountDisabled}
              onClick={() =>
                onDecreaseAmountClick(item.item, item.additionalAmount)
              }
            >
              <Icon type="edit" />
            </Button>
          )}
        </div>
      );
    },
  },
  {
    Header: withArrow('title.stocklist.currentAmount'),
    accessor: 'currentAmount',
    resizable: true,
    minWidth: 110,
    Cell: cell => <div>{cell.value}</div>,
  },
  {
    Header: withArrow('title.stocklist.soldOut'),
    accessor: 'isSoldOut',
    resizable: false,
    minWidth: 110,
    Cell: cell => (
      <div className={style.cell__soldOut}>
        {cell.value && <span className={style.cell__soldOut__mark} />}
      </div>
    ),
  },
];

export default config;
