import { action, computed, observable, reaction } from 'mobx';
import { DateTime } from 'luxon';

import { getDateTimeWithLocale, getLocalDateTime } from 'helpers/datetime';

import { CopyMode, ICopyMenu } from 'stores/Menu/MenuCopy/types';
import { CreateMenuLineDto, MenuItem } from 'stores/Menu/types';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import {
  getDatesPeriod,
  getMenuLineItemsDto,
} from 'stores/Menu/MenuCopy/helpers';

export class CopyMenuLineForm implements ICopyMenu {
  readonly copyMode = CopyMode.MENU_LINE;

  @observable periodFrom: [DateTime, DateTime] = [
    getLocalDateTime().startOf('week'),
    getLocalDateTime().endOf('week'),
  ];
  @observable periodTo: [DateTime, DateTime] = [
    getLocalDateTime().startOf('week'),
    getLocalDateTime().endOf('week'),
  ];
  @observable menuLineId = '';

  private menuPlanner: MenuPlannerStore;

  constructor(menuPlanner: MenuPlannerStore) {
    this.menuPlanner = menuPlanner;

    reaction(
      () => this.periodFrom,
      () => {
        this.menuLineId = '';
      },
    );
  }

  @computed
  get menuLineOptions() {
    return this.menuPlanner.plannerLines.map(line => ({
      label: line.title,
      value: line.id,
    }));
  }

  @computed
  get copyOnPeriod(): string[] {
    const [periodStart, periodEnd] = this.periodTo;

    return getDatesPeriod(periodStart, periodEnd);
  }

  @action.bound
  changePeriodFrom([date]: DateTime[]) {
    this.periodFrom = [date.startOf('week'), date.endOf('week')];
    this.menuPlanner.changeDate([date]);
  }

  @action.bound
  changePeriodTo([from, to]: DateTime[]) {
    this.periodTo = [from.startOf('week'), to.endOf('week')];
  }

  @action
  setMenuLineId = (id: string) => () => {
    this.menuLineId = id;
  };

  @action.bound
  changeMenuLineId(id?: string | null) {
    if (id) {
      this.menuLineId = id;
    }
  }

  @computed
  get dataToCopy(): CreateMenuLineDto[] {
    const menuLine = this.menuPlanner.plannerLines.find(
      line => line.id === this.menuLineId,
    );

    if (!menuLine) {
      return [];
    }

    const itemsForWeek: {
      [key: number]: MenuItem[];
    } = menuLine.days.reduce((acc, day, currentIndex) => {
      acc[currentIndex] = day.items;
      return acc;
    }, {});

    return [
      {
        line: menuLine.id,
        days: this.copyOnPeriod.map(date => {
          return {
            date,
            items: getMenuLineItemsDto(
              itemsForWeek[getDateTimeWithLocale(date).weekday - 1],
            ),
          };
        }),
      },
    ];
  }

  @computed
  get isCopyDisabled(): boolean {
    return !this.dataToCopy.length;
  }

  @action.bound
  reset() {
    this.menuLineId = '';
  }
}
