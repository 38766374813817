import React from 'react';
import { Column } from 'react-table';

import { withArrow } from 'components/Table/helpers';
import { getDateTimeFromFormat } from 'helpers/datetime';

import style from './StocklistReport.module.scss';

const config = (): Column[] => [
  {
    Header: withArrow('title.date'),
    accessor: 'date',
    resizable: false,
    width: 120,
    sortMethod: (a: string, b: string) =>
      getDateTimeFromFormat(a).toMillis() - getDateTimeFromFormat(b).toMillis(),
  },
  {
    Header: withArrow('title.report.name'),
    accessor: 'title',
    resizable: true,
    Cell: cell => (
      <div className={style.cell__text} title={cell.value}>
        {cell.value}
      </div>
    ),
  },
  {
    Header: withArrow('title.report.stocklist.fullAmount'),
    accessor: 'fullAmount',
    resizable: true,
    width: 220,
  },
  {
    Header: withArrow('title.report.stocklist.additionalAmount'),
    accessor: 'additionalAmount',
    resizable: true,
    width: 200,
  },
  {
    Header: withArrow('title.report.stocklist.changeStockMessage'),
    accessor: 'changeStockMessage',
    resizable: true,
    width: 180,
    Cell: cell => (
      <div className={style.cell__text} title={cell.value}>
        {cell.value}
      </div>
    ),
  },
  {
    Header: withArrow('title.report.stocklist.redundantAmount'),
    accessor: 'redundantAmount',
    resizable: true,
    width: 200,
  },
];

export default config;
