import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/Button';
import Icon, { icons } from 'components/Icon/Icon';

import style from './ActionSelect.module.scss';

export interface ActionSelectOption {
  title: string;
  action: () => void;
}

interface ActionSelectStyles {
  buttonStyle?: string;
  listStyle?: string;
}

interface Props {
  title?: string;
  icon?: string;
  options: ActionSelectOption[];
  styles?: ActionSelectStyles;
}

@observer
class ActionSelect extends Component<Props> {
  @observable open = false;
  ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutsideHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutsideHandler);
  }

  clickOutsideHandler = event => {
    const { current } = this.ref;

    if (current && !current.contains(event.target)) {
      this.open = false;
    }
  };

  toggle = () => {
    this.open = !this.open;
  };

  onActionClick = fn => () => {
    this.toggle();
    fn();
  };

  render() {
    const { options, title, icon, styles } = this.props;

    return (
      <div className={style.wrapper} ref={this.ref}>
        <Button
          styleType="text"
          className={classNames(style.btn, styles?.buttonStyle, {
            [style.btn__active]: this.open,
          })}
          onClick={this.toggle}
        >
          {title && (
            <>
              <FormattedMessage id={title} />

              <Icon type={this.open ? 'arrowTop' : 'arrowBottom'} />
            </>
          )}

          {icon && <Icon type={icon as keyof typeof icons} />}
        </Button>

        {this.open && (
          <ul className={classNames(style.buttons__list, styles?.listStyle)}>
            {options.map(option => (
              <li
                key={option.title}
                onClick={this.onActionClick(option.action)}
              >
                <FormattedMessage id={option.title} />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default ActionSelect;
