import { action, observable, reaction } from 'mobx';

import { CopyPLUFromDayForm } from 'stores/Forms/Settings/CopyMode/CopyPLUFromDayForm';

import PLUPlannerStore, { PLUPlannerTab } from './PLUPlannerStore';
import { CopyPLUFromWeekForm } from 'stores/Forms/Settings/CopyMode/CopyPLUFromWeekForm';
import { PLUItemComponent } from 'http/Api/PLUItems/types';

export enum PLUCopyModeType {
  Day = 'DAY',
  Week = 'WEEK',
}

const copyModeForms = {
  [PLUCopyModeType.Day]: CopyPLUFromDayForm,
  [PLUCopyModeType.Week]: CopyPLUFromWeekForm,
};

type CopyModeForm = CopyPLUFromDayForm | CopyPLUFromWeekForm;

class PLUPageStore {
  @observable public isCopyModeEnabled = false;
  @observable public copyModeType: PLUCopyModeType | null = null;
  @observable public copyModeForm: CopyModeForm | null = null;

  @observable public plannerStore = new PLUPlannerStore();

  constructor() {
    reaction(
      () => this.copyModeType,
      () => {
        if (this.copyModeType) {
          const component =
            this.plannerStore.tab === PLUPlannerTab.Spt
              ? PLUItemComponent.Spt
              : PLUItemComponent.Consumer;

          this.initCopyMenuForm(this.copyModeType, component);
        }
      },
    );
  }

  @action
  public initCopyMenuForm = (
    copyModeType: PLUCopyModeType,
    component: PLUItemComponent,
  ) => {
    this.copyModeForm = new copyModeForms[copyModeType](
      this.plannerStore,
      component,
    );
  };

  @action
  public toggleIsCopyModeEnabled = () => {
    if (this.isCopyModeEnabled) {
      this.copyModeType = null;
    }

    if (this.copyModeType === null && !this.isCopyModeEnabled) {
      this.copyModeType = PLUCopyModeType.Day;
    }

    this.isCopyModeEnabled = !this.isCopyModeEnabled;
  };

  @action
  public changeCopyModeType = (mode: PLUCopyModeType | null) => {
    this.copyModeType = mode;

    if (!this.isCopyModeEnabled) {
      this.toggleIsCopyModeEnabled();
    }
  };
}

export default PLUPageStore;
