import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Calendar from 'react-calendar/dist/entry.nostyle';
import { observable } from 'mobx';
import { DateTime } from 'luxon';
import classNames from 'classnames';

import style from './Datepicker.module.scss';

import Button from 'components/Button/Button';
import RadioField from 'components/Form/Fields/Radio/RadioField';

import RootStore from 'stores/RootStore';

import {
  addDaysToDate,
  DATE_FORMAT,
  getDateTimeFromJSDate,
  getLocalDateTime,
  isAfterDate,
  QUICK_TIME_PERIODS,
} from 'helpers/datetime';
import Icon from '../../../Icon/Icon';

interface Props {
  value: [DateTime, DateTime | undefined] | DateTime;
  styles?: {
    calendar?: string;
    calendarWrapper?: string;
    button?: string;
    icon?: string;
  };
  selectRange?: boolean;
  showPeriods?: boolean;
  onChange: (value: DateTime[]) => void;
  minDate?: Date;
  maxDate?: Date;
  notEndDateContent?: string;
  icon?: boolean;
  dateFormat?: string;
  locale?: string;
  showWeekNumbers?: boolean;
  disabled?: boolean;
  isDateDisabled?: (date: Date) => boolean;
}

@observer
class Datepicker extends Component<Props> {
  @observable period: QUICK_TIME_PERIODS | undefined;
  @observable open = false;

  calendarRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside);
    document.addEventListener('dblclick', this.onCloseCalendar);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);
    document.removeEventListener('dblclick', this.onCloseCalendar);
  }

  onCloseCalendar = () => {
    this.open = false;
  };

  onClickOutside = event => {
    const { current } = this.calendarRef;

    if (current && !current.contains(event.target)) {
      this.open = false;
    }
  };

  resetPeriod = () => {
    this.period = undefined;
  };

  onDateChange = value => {
    this.props.onChange(
      // @ts-ignore
      Array.isArray(value)
        ? value.map(it => getDateTimeFromJSDate(it))
        : Array.isArray(this.props.value)
        ? [getDateTimeFromJSDate(value)]
        : getDateTimeFromJSDate(value),
    );
  };

  toggle = () => {
    this.open = !this.open;
  };

  onClickWeekNumber = (_: number, date: Date) => {
    const { isDateDisabled } = this.props;

    const dateTime = getDateTimeFromJSDate(date);

    const weekDateFrom = dateTime.startOf('week').toJSDate();
    const weekDateTo = dateTime.endOf('week').toJSDate();

    let start: Date | null = null;
    let end: Date | null = null;

    for (
      let weekDate = weekDateFrom;
      !isAfterDate(weekDate, weekDateTo);
      weekDate = addDaysToDate(weekDate, 1)
    ) {
      const isDisabled = isDateDisabled?.(weekDate) ?? false;
      if (isDisabled && end) break;
      if (isDisabled) continue;

      if (!start) {
        start = weekDate;
        end = weekDate;

        continue;
      }

      end = weekDate;
    }

    if (start && end) {
      this.props.onChange([
        getDateTimeFromJSDate(start),
        getDateTimeFromJSDate(end),
      ]);
    }
  };

  onPeriodChange = value => {
    const date = getLocalDateTime();

    this.period = value;

    switch (this.period) {
      case QUICK_TIME_PERIODS.CURRENT_WEEK:
        this.props.onChange([date.startOf('week'), date.endOf('week')]);
        break;
      case QUICK_TIME_PERIODS.CURRENT_MONTH:
        this.props.onChange([date.startOf('month'), date.endOf('month')]);
        break;
      case QUICK_TIME_PERIODS.CURRENT_QUARTER:
        this.props.onChange([date.startOf('quarter'), date.endOf('quarter')]);
        break;
      case QUICK_TIME_PERIODS.CURRENT_YEAR:
        this.props.onChange([date.startOf('year'), date.endOf('year')]);
        break;
    }
  };

  renderFormattedDate = (date?: DateTime) => {
    if (!date) return '';

    return this.props.locale
      ? date
          .setLocale(this.props.locale)
          .toFormat(this.props.dateFormat || DATE_FORMAT)
      : date.toFormat(this.props.dateFormat || DATE_FORMAT);
  };

  handleTileDisabled = (options: { date: Date }) => {
    const { isDateDisabled } = this.props;
    if (!isDateDisabled) return false;

    return isDateDisabled(options.date);
  };

  render() {
    const {
      value,
      minDate,
      maxDate,
      selectRange = true,
      showPeriods = true,
      notEndDateContent = '',
      icon = false,
      styles,
      showWeekNumbers = false,
      disabled,
    } = this.props;
    let calendarValue;
    let startDate: DateTime;
    let endDate: DateTime | undefined;
    if (Array.isArray(value)) {
      [startDate, endDate] = value;
      const jsDateStart = startDate?.toJSDate();
      const jsDateEnd = endDate?.toJSDate();
      calendarValue = [jsDateStart, jsDateEnd];
    } else {
      startDate = value;
      calendarValue = value ? (value as DateTime).toJSDate() : value;
    }
    const formattedStartDate = this.renderFormattedDate(startDate);
    const formattedEndDate = endDate && this.renderFormattedDate(endDate);
    const showEndDate = endDate && formattedEndDate !== formattedStartDate;

    return (
      <div
        className={classNames(style.calendar, styles?.calendar)}
        ref={this.calendarRef}
      >
        <Button
          styleType="text"
          className={classNames(style.btn__date, styles?.button)}
          disabled={disabled}
          onClick={this.toggle}
        >
          <span>{formattedStartDate}</span>
          {showEndDate && (
            <>
              {' - '}
              <span>{formattedEndDate}</span>
            </>
          )}
          {!endDate && notEndDateContent}
          {icon && (
            <Icon
              type="calendar"
              className={classNames(style.icon, styles?.icon)}
            />
          )}
        </Button>

        {this.open && (
          <div
            className={classNames(
              style.calendar__wrapper,
              styles?.calendarWrapper,
            )}
          >
            <Calendar
              className={style.react__calendar}
              // @ts-ignore
              value={calendarValue}
              onChange={this.onDateChange}
              onClickDay={this.resetPeriod}
              selectRange={selectRange}
              activeStartDate={
                Array.isArray(calendarValue) ? calendarValue[0] : calendarValue
              }
              locale={RootStore.localization.locale}
              prev2Label={null}
              next2Label={null}
              minDate={minDate}
              maxDate={maxDate}
              showWeekNumbers={showWeekNumbers}
              onClickWeekNumber={this.onClickWeekNumber}
              tileDisabled={this.handleTileDisabled}
            />

            {showPeriods && (
              <div className={style.calendar__periods}>
                {Object.values(QUICK_TIME_PERIODS).map(it => (
                  <RadioField
                    value={it}
                    key={it}
                    title={it}
                    onChange={this.onPeriodChange}
                    checked={it === this.period}
                    className={style.period}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Datepicker;
