import { computed, observable } from 'mobx';

import StocklistAdditionalAmountModalForm from 'stores/Forms/Stocklist/StocklistAdditionalAmountModalForm';
import { PromiseService } from 'services/PromiseService';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';

class StocklistAdditionalAmountModalStore {
  @observable public form = new StocklistAdditionalAmountModalForm();

  @observable private _promiseService = new PromiseService();
  private _id: string;

  constructor(id: string) {
    this._id = id;
  }

  public submit = async () => {
    if (this.isDisabled || this.loading) return;

    try {
      await this._promiseService.add(() =>
        httpFacade.stocklist.addAdditionalAmount(this._id, this.form.amount),
      );
    } catch (error) {
      Log.info(error);
    }
  };

  @computed
  public get isDisabled() {
    return this.form.invalidFields.length;
  }

  @computed
  public get loading() {
    return this._promiseService.isPending;
  }
}

export default StocklistAdditionalAmountModalStore;
