import { DeliveryTimeSlot, OrderModel } from '../UserProfile/types';

export interface OnlineOrderGroup {
  deliveryTimeSlot?: DeliveryTimeSlot;
  orders: OrderModel[];
}

export interface OrderLocation {
  id: string;
  title: string;
}

export interface OnlineOrdersFilters {
  places: Array<OnlineOrderPlace | string>;
  statuses: OnlineOrderStatus[];
}

export enum OnlineOrderPlace {
  ALL = 'ALL',
  TAKE_AWAY = 'TAKE_AWAY',
  IN_PLACE = 'IN_PLACE',
  DELIVERY = 'DELIVERY',
}

export enum OnlineOrderStatus {
  ALL = 'ALL',
  DELIVERED = 'delivered',
  PENDING = 'pending',
}
