import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import PLUPageStore, { PLUCopyModeType } from 'stores/Settings/PLUPageStore';
import { CopyPLUFromDayForm } from 'stores/Forms/Settings/CopyMode/CopyPLUFromDayForm';
import { CopyPLUFromWeekForm } from 'stores/Forms/Settings/CopyMode/CopyPLUFromWeekForm';

import SwitchButton from 'components/Form/Fields/SwitchButton/SwitchButton';
import RadioField from 'components/Form/Fields/Radio/RadioField';
import { CopyFromDayComponent } from './CopyFromDayComponent';
import { CopyFromWeekComponent } from './CopyFromWeekComponent';

import style from './CopyMode.module.scss';

interface IProps {
  store: PLUPageStore;
}

@observer
class CopyMode extends Component<IProps> {
  private _renderFormByType() {
    const { store } = this.props;

    if (!store.isCopyModeEnabled) {
      return null;
    }

    switch (store.copyModeType) {
      case PLUCopyModeType.Day:
        const dayForm = store.copyModeForm as CopyPLUFromDayForm;

        return (
          <CopyFromDayComponent
            dateFrom={dayForm.from}
            periodTo={dayForm.periodTo}
            days={dayForm.selectedDays}
            component={dayForm.component}
            disabled={dayForm.isCopyDisabled}
            onDateFromChange={dayForm.changeDateFrom}
            onPeriodToChange={dayForm.changePeriodTo}
            onDaysChange={dayForm.changeSelectedDays}
            onComponentChange={dayForm.changeComponent}
            onSubmit={dayForm.copy}
          />
        );
      case PLUCopyModeType.Week:
        const weekForm = store.copyModeForm as CopyPLUFromWeekForm;

        return (
          <CopyFromWeekComponent
            periodFrom={weekForm.periodFrom}
            periodTo={weekForm.periodTo}
            component={weekForm.component}
            disabled={weekForm.isCopyDisabled}
            onPeriodFromChange={weekForm.changePeriodFrom}
            onPeriodToChange={weekForm.changePeriodTo}
            onComponentChange={weekForm.changeComponent}
            onSubmit={weekForm.copy}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { store } = this.props;

    return (
      <div className={style.section}>
        <div className={style.header}>
          <h2 className={style.title}>
            <FormattedMessage id="menu.copy.title" />
          </h2>
          <div className={style.modeSwitcher}>
            {Object.values(PLUCopyModeType).map(mode => (
              <RadioField
                key={mode}
                name="copyMode"
                value={mode}
                title={mode}
                checked={store.copyModeType === mode}
                onChange={() => store.changeCopyModeType(mode)}
                className={style.modeRadioButton}
              />
            ))}
          </div>
          <SwitchButton
            checked={store.isCopyModeEnabled}
            onChange={store.toggleIsCopyModeEnabled}
          />
        </div>
        <div className={style.form} aria-expanded={store.isCopyModeEnabled}>
          <div className={style.formContent}>{this._renderFormByType()}</div>
        </div>
      </div>
    );
  }
}

export { CopyMode };
