import React from 'react';

import style from './AsideMenu.module.scss';

import { hasRole, Roles } from 'helpers/roles';

import AppLink from 'components/AppLink/AppLink';
import Icon from 'components/Icon/Icon';

interface Props {
  iconType: React.ComponentProps<typeof Icon>['type'];
  to: string;
  roles: Roles[];
  children: React.ReactNode;
}

const NavigationItem = ({ iconType, to, roles, children }: Props) => {
  if (!hasRole(roles)) return null;

  return (
    <li>
      <AppLink to={to} className={style.link} activeClass={style.link__active}>
        <Icon className={style.link__icon} aria-label="icon" type={iconType} />

        <span className={style.link__text}>{children}</span>
      </AppLink>
    </li>
  );
};

export default NavigationItem;
