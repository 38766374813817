import { action, computed, observable } from 'mobx';

import StocklistAddAmountModalForm from 'stores/Forms/Stocklist/StocklistAddAmountModalForm';

export enum StocklistAddAmountModalStep {
  Confirm,
  Amount,
  AdditionalProductionConfirm,
}

class StocklistAddAmountModalStore {
  @observable public step = StocklistAddAmountModalStep.Confirm;
  @observable public form = new StocklistAddAmountModalForm();

  @action.bound
  public goNextStep() {
    const isLastStep =
      this.step === StocklistAddAmountModalStep.AdditionalProductionConfirm;
    if (isLastStep) return;

    this.step = this.step + 1;
  }

  @computed
  public get isNextStepDisabled() {
    return (
      this.step === StocklistAddAmountModalStep.Amount &&
      !this.form.isValid('amount')
    );
  }
}

export default StocklistAddAmountModalStore;
