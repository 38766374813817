import { action, computed, observable } from 'mobx';
import { DateTime } from 'luxon';

import { getLocalDateTime } from 'helpers/datetime';

import { CreateMenuLineDto } from 'stores/Menu/types';
import MenuPlannerStore from 'stores/Menu/MenuPlannerStore';
import { CopyMode, ICopyMenu } from 'stores/Menu/MenuCopy/types';
import {
  getDatesPeriod,
  getMenuLineItemsDto,
  weekDayOptions,
} from 'stores/Menu/MenuCopy/helpers';

export class CopyMenuFromDayForm implements ICopyMenu {
  readonly copyMode = CopyMode.DAY;

  @observable from = getLocalDateTime();
  @observable period: [DateTime, DateTime] = [
    getLocalDateTime(),
    getLocalDateTime(),
  ];
  @observable selectedOptionIds: string[] = [];

  private menuPlanner: MenuPlannerStore;

  constructor(menuPlanner: MenuPlannerStore) {
    this.menuPlanner = menuPlanner;
  }

  @computed
  get copyOnPeriod(): string[] {
    const [periodStart, periodEnd] = this.period;

    return getDatesPeriod(periodStart, periodEnd, this.selectedOptionIds);
  }

  @computed
  get dataToCopy(): CreateMenuLineDto[] {
    return this.menuPlanner.plannerLines.map(plannerLine => {
      const fromSelectedDayItems =
        plannerLine.days.find(day => day.date === this.from.toISODate())
          ?.items ?? [];

      return {
        line: plannerLine.id,
        days: this.copyOnPeriod.map(date => ({
          date,
          items: getMenuLineItemsDto(fromSelectedDayItems),
        })),
      };
    });
  }

  @computed
  get isCopyDisabled(): boolean {
    return (
      !this.dataToCopy.length ||
      this.dataToCopy.every(line => line.days.every(day => !day.items.length))
    );
  }

  @action
  setDateFrom = (date: DateTime) => () => {
    this.from = date;
  };

  @action.bound
  changeDateFrom([date]: DateTime[]) {
    this.from = date;
    this.menuPlanner.changeDate([date]);
  }

  @action.bound
  changePeriod([from, to]: DateTime[]) {
    this.period = [from, to];
  }

  @action.bound
  toggleOption(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      this.selectedOptionIds = this.selectedOptionIds.filter(
        id => id !== optionId,
      );
    } else {
      this.selectedOptionIds.push(optionId);
    }
  }

  @action.bound
  clearSelectedOptions() {
    this.selectedOptionIds = [];
  }

  @action.bound
  selectWeekdays() {
    this.selectedOptionIds = weekDayOptions.reduce((acc, option) => {
      if (!option.holiday) {
        acc.push(option.id);
      }

      return acc;
    }, [] as string[]);
  }

  @action.bound
  reset() {
    this.selectedOptionIds = [];
  }
}
