import React, { Component } from 'react';
import classNames from 'classnames';

import style from './ModalBody.module.scss';

interface Props {
  children?: JSX.Element | null;
  className?: string;
}

class ModalBody extends Component<Props> {
  render() {
    const { className, children } = this.props;

    return <div className={classNames(style.body, className)}>{children}</div>;
  }
}

export default ModalBody;
