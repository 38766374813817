import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import RootStore from 'stores/RootStore';
import { ModalProps } from 'stores/ModalStore';
import StocklistDecreaseAmountModalStore from 'stores/Stocklist/StocklistDecreaseAmountModalStore';
import { StocklistDecreaseAmountDescriptionType } from 'stores/Forms/Stocklist/types';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';
import { FormControl } from 'components/Form/FormControl/FormControl';
import ModalHeader from 'components/Modals/Components/ModalHeader/ModalHeader';
import ModalBody from 'components/Modals/Components/ModalBody/ModalBody';
import ModalControls from 'components/Modals/Components/ModalControls/ModalControls';
import SelectField from 'components/Form/Fields/Select/SelectField';

import style from './StocklistDecreaseAmountModal.module.scss';

interface Props extends ModalProps {
  id: string;
  additionalAmount: number;
  onChange?: () => void;
}

@observer
class StocklistDecreaseAmountModal extends Component<Props> {
  private _store = new StocklistDecreaseAmountModalStore(
    this.props.id,
    this.props.additionalAmount,
  );

  render() {
    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <ModalHeader>
          <FormattedMessage id="modal.stocklist.decreaseAmount.title" />
        </ModalHeader>

        <ModalBody>
          <>
            <div className={style.form__field}>
              <div className={style.form__field__amount}>
                <FormControl
                  form={this._store.form}
                  name="amount"
                  className={style.field__amount}
                  render={propsField => (
                    <TextField
                      {...propsField}
                      disabled={!this._store.form.isEditMode}
                    />
                  )}
                />

                <Button
                  className={style.btn__edit}
                  styleType="primary"
                  disabled={this._store.form.isEditMode}
                  onClick={this._handleEditButtonClick}
                >
                  <FormattedMessage id="button.edit" />
                </Button>
              </div>
            </div>

            <div className={style.form__field}>
              <FormControl
                form={this._store.form}
                name="descriptionType"
                className={style.field__select}
                render={props => (
                  <SelectField
                    {...props}
                    options={this._store.descriptionTypeOptions}
                    onChange={v => this._handleDescriptionTypeChange(v)}
                    disabled={!this._store.form.isEditMode}
                  />
                )}
              />
            </div>

            {this._store.form.descriptionType ===
              StocklistDecreaseAmountDescriptionType.Custom && (
              <div className={style.form__field}>
                <FormControl
                  form={this._store.form}
                  name="description"
                  render={propsField => (
                    <TextField
                      {...propsField}
                      placeholder={RootStore.localization.formatMessage(
                        'modal.stocklist.decreaseAmount.descriptionType.custom.placeholder',
                      )}
                    />
                  )}
                />
              </div>
            )}
          </>
        </ModalBody>

        <ModalControls>
          <>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this._handleClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={style.btn}
              styleType="primary"
              disabled={this._store.isDisabled || this._store.loading}
              onClick={this._handleSubmit}
            >
              <FormattedMessage id="button.saveChanges" />
            </Button>
          </>
        </ModalControls>
      </Modal>
    );
  }

  private _handleClose = () => {
    this.props.onClose();
  };

  private _handleDescriptionTypeChange = (
    descriptionType: StocklistDecreaseAmountDescriptionType,
  ) => {
    this._store.form.changeDescriptionType(descriptionType);
  };

  private _handleEditButtonClick = () => {
    this._store.form.changeIsEditMode(true);
  };

  private _handleSubmit = async () => {
    const { onChange, onClose } = this.props;

    await this._store.submit();

    onChange?.();
    onClose();
  };
}

export default StocklistDecreaseAmountModal;
