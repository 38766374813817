import { WeekDayOption } from 'stores/Menu/MenuCopy/types';
import { MenuItem } from '../types';

export const getDatesPeriod = (
  periodStart,
  periodEnd,
  dayNumbers: string[] = [],
) => {
  const period: string[] = [];

  for (let day = periodStart; day <= periodEnd; day = day.plus({ day: 1 })) {
    if (dayNumbers.length === 0) {
      period.push(day.toISODate());
    } else {
      if (dayNumbers.includes(String(day.weekday))) {
        period.push(day.toISODate());
      }
    }
  }

  return period;
};

export const weekDayOptions: WeekDayOption[] = [
  {
    label: 'MONDAY',
    id: '1',
    holiday: false,
  },
  {
    label: 'TUESDAY',
    id: '2',
    holiday: false,
  },
  {
    label: 'WEDNESDAY',
    id: '3',
    holiday: false,
  },
  {
    label: 'THURSDAY',
    id: '4',
    holiday: false,
  },
  {
    label: 'FRIDAY',
    id: '5',
    holiday: false,
  },
  {
    label: 'SATURDAY',
    id: '6',
    holiday: true,
  },
  {
    label: 'SUNDAY',
    id: '7',
    holiday: true,
  },
];

export const getMenuLineItemsDto = (items: MenuItem[]) => {
  return items.map(item => {
    const { id } = item;

    return {
      item: id,
      initStock: -1,
      fixedAmount: true,
      additionalAmount: 0,
      currentAmount: -1,
    };
  });
};
