import { observable } from 'mobx';

import {
  BaseFormModel,
  displayName,
  isNumberMore,
  isRequired,
} from '../../BaseForm';

class StocklistAdditionalAmountModalForm extends BaseFormModel {
  @observable
  @displayName('modal.stocklist.additionalAmount.text')
  @isRequired()
  @isNumberMore(0)
  amount = '';
}

export default StocklistAdditionalAmountModalForm;
