import { action, computed, observable } from 'mobx';

import AppRouter from 'stores/AppRouter';
import PLUItemForm from 'stores/Forms/Settings/PLUItemForm';
import { DishGroupManagement, MenuItem } from 'stores/Menu/types';
import { PLUItemMenuItemType } from 'stores/Forms/Settings/types';
import { sortByAccessor } from 'helpers/accessors';
import { SortConfig } from 'helpers/types';
import httpFacade from 'http/httpFacade';
import { CreateUpdatePLUItemDto } from 'http/Api/PLUItems/types';
import {
  addDaysToDate,
  getDate,
  getDatesFromPeriod,
  getDateTimeFromJSDate,
  isAfterDate,
  isBeforeDate,
} from 'helpers/datetime';
import ModalStore from 'stores/ModalStore';
import { ROUTES } from 'routes/routes';

import { IGroupTag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import WarningModal from 'components/Modals/Warning/WarningModal';
import { TimeRangeType } from 'components/Form/Fields/TimeRange/TimeRange';
import { PLUItemSteps } from './types';

class PLUItemStore {
  public id: string;

  @observable public activeStep = PLUItemSteps.Software;
  @observable public form = new PLUItemForm();
  @observable public loading = false;

  @observable private _menuItems: MenuItem[] = [];
  @observable private _dishGroups: DishGroupManagement[] = [];

  private _defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  constructor(id?: string) {
    this.id = id || '';
  }

  @action
  public init = async () => {
    try {
      this.loading = true;

      const [menuItems, dishGroups, pluItem] = await Promise.all([
        httpFacade.menuItems.fetchMenuItems(),
        httpFacade.menu.fetchCategories(),
        this._fetchPLUItem(),
      ]);

      this._menuItems = menuItems.data;
      this._dishGroups = dishGroups.data;

      if (pluItem) {
        const {
          alias,
          component,
          from,
          to,
          dates,
          cell,
          menuItemId,
        } = pluItem.data;

        this.form.alias = alias;
        this.form.component = component;
        this.form.availableTime = [from, to];
        this.form.cell = cell;
        this.form.menuItemId = menuItemId;

        const isTemporary =
          !from.startsWith('00:00') || !to.startsWith('23:59');
        if (isTemporary) {
          this.form.menuItemType = PLUItemMenuItemType.Temporary;
        }

        const period = this._getPeriodFromDates(dates);
        this.form.period = [
          getDateTimeFromJSDate(period[0]),
          getDateTimeFromJSDate(period[1]),
        ];
        this.form.weekDays = this._getWeekDaysFromPeriod(period, dates);
      }
    } catch (error) {
      AppRouter.goBack();
    } finally {
      this.loading = false;
    }
  };

  @action
  public handleSubmit = async () => {
    if (!this.form.validate()) return;

    const {
      cell,
      component,
      alias,
      period,
      weekDays,
      availableTime,
      menuItemId,
      menuItemType,
    } = this.form;

    const [from, to] = this._getTimeRange(availableTime, menuItemType);
    const dates = getDatesFromPeriod(period, weekDays);

    if (!dates.length) return;

    const data: CreateUpdatePLUItemDto = {
      cell: cell!,
      component,
      alias,
      dates,
      from,
      to,
      menuItemUUID: menuItemId,
    };

    try {
      this.loading = true;

      if (this.id) {
        await httpFacade.pluItems.updatePLUItem(this.id, data);
      } else {
        await httpFacade.pluItems.createPLUItem(data);
      }
    } finally {
      this.loading = false;
    }

    AppRouter.goBack();
  };

  @action
  public handleDelete = async () => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    try {
      this.loading = true;

      await httpFacade.pluItems.deletePLUItem(this.id);

      AppRouter.push(ROUTES.admin.settings);
    } finally {
      this.loading = false;
    }
  };

  public handleStepBack = () => {
    if (this.activeStep === PLUItemSteps.Software) {
      AppRouter.goBack();
      return;
    }

    this._setStep(this.activeStep - 1);
  };

  public handleStepNext = () => {
    this._setStep(this.activeStep + 1);
  };

  public handleStepChange = (newStep: PLUItemSteps) => {
    this._setStep(newStep);
  };

  @action
  private _setStep = (step: PLUItemSteps) => {
    this.activeStep = step;
  };

  private _fetchPLUItem = () => {
    if (!this.id) return;

    return httpFacade.pluItems.fetchPLUItem(this.id);
  };

  private _getTimeRange = (
    range: TimeRangeType,
    itemType: PLUItemMenuItemType,
  ) => {
    let [from, to] = range;

    if (!from || itemType === PLUItemMenuItemType.Standard) {
      from = '00:00';
    }
    if (!to || itemType === PLUItemMenuItemType.Standard) {
      to = '23:59';
    }

    return [from, to];
  };

  private _getPeriodFromDates = (dates: string[]) => {
    if (!dates.length) return [];

    let from = new Date(dates[0]);
    let to = new Date(dates[0]);

    for (const date of dates) {
      const formattedDate = new Date(date);

      if (isBeforeDate(formattedDate, from)) from = formattedDate;
      if (isAfterDate(formattedDate, to)) to = formattedDate;
    }

    return [from, to];
  };

  private _getWeekDaysFromPeriod = (
    period: Date[],
    selectedDates: string[],
  ) => {
    const [from, to] = period;

    const weekDays: string[] = [];

    let nextDate = from;
    while (!isAfterDate(nextDate, to)) {
      const isSelected = selectedDates.includes(getDate(nextDate));

      if (isSelected) {
        const date = getDateTimeFromJSDate(nextDate);
        const weekDay = date.weekday.toString();
        weekDays.push(weekDay);
      }

      nextDate = addDaysToDate(nextDate, 1);
    }

    const uniqueWeekDays = [...new Set(weekDays)];
    if (uniqueWeekDays.length === 7) return [];
    return weekDays;
  };

  @computed
  public get menuItems(): IGroupTag[] {
    const menuTags: IGroupTag[] = [];

    const sortedDishGroups = [...this._dishGroups].sort(
      sortByAccessor(this._defaultSortByTitle),
    );
    const menuItemsMap: Map<string, MenuItem[]> = new Map();

    this._menuItems.forEach(item => {
      const items = menuItemsMap.get(item.dishGroup.id) || [];
      menuItemsMap.set(item.dishGroup.id, [...items, item]);
    });

    sortedDishGroups.forEach(dish => {
      const menuItems =
        menuItemsMap
          .get(dish.id)
          ?.slice()
          .sort(sortByAccessor(this._defaultSortByTitle))
          .map(item => ({
            id: item.id,
            title: item.title,
            groupId: dish.id,
          })) || [];

      menuTags.push(
        { id: dish.id, title: dish.title, isGroup: true },
        ...menuItems,
      );
    });

    return menuTags;
  }
}

export default PLUItemStore;
