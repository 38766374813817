import React, { Component } from 'react';
import classNames from 'classnames';

import style from './ModalControls.module.scss';

interface Props {
  children?: JSX.Element | null;
  className?: string;
}

class ModalControls extends Component<Props> {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classNames(style.controls, className)}>{children}</div>
    );
  }
}

export default ModalControls;
