import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';

import RootStore from 'stores/RootStore';
import { isAfterWeek } from 'helpers/datetime';
import { PLUItemComponent } from 'http/Api/PLUItems/types';

import SoftwareComponentSelect from 'components/Form/Fields/Select/SoftwareComponentSelect/SoftwareComponentSelect';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';

import style from './CopyMode.module.scss';

interface IProps {
  periodFrom: [DateTime, DateTime];
  periodTo: [DateTime, DateTime];
  component: PLUItemComponent;
  disabled?: boolean;
  onPeriodFromChange: (dates: DateTime[]) => void;
  onPeriodToChange: (dates: DateTime[]) => void;
  onComponentChange: (component: PLUItemComponent | null) => void;
  onSubmit: () => void;
}

class CopyFromWeekComponent extends Component<IProps> {
  private _weekFormat = `'${RootStore.localization.formatMessage('WEEK')}' W`;

  render() {
    const {
      periodFrom,
      periodTo,
      component,
      disabled,
      onPeriodFromChange,
      onPeriodToChange,
      onComponentChange,
      onSubmit,
    } = this.props;

    return (
      <>
        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.from" />
          </div>
          <Datepicker
            value={periodFrom}
            onChange={onPeriodFromChange}
            selectRange={false}
            showPeriods={false}
            dateFormat={this._weekFormat}
            showWeekNumbers
            icon
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.toPeriod" />
          </div>
          <Datepicker
            value={periodTo}
            selectRange={true}
            showPeriods={false}
            dateFormat={this._weekFormat}
            showWeekNumbers={true}
            icon={true}
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
            onChange={onPeriodToChange}
            isDateDisabled={this._isPeriodToDayDisabled}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.component" />
          </div>
          <SoftwareComponentSelect
            value={component}
            className={style.select}
            onChange={onComponentChange}
          />
        </div>

        <Button
          onClick={onSubmit}
          className={style.btnCopy}
          disabled={disabled}
        >
          <FormattedMessage id="button.copy" />
        </Button>
      </>
    );
  }

  private _isPeriodToDayDisabled = (date: Date) => {
    return !isAfterWeek(date, new Date());
  };
}

export { CopyFromWeekComponent };
