import React, { Component } from 'react';
import { observer } from 'mobx-react';

import StocklistReportStore from 'stores/Report/StocklistReportStore';
import StocklistReportForm from 'stores/Forms/Reports/StocklistReportForm';

import Spinner from 'components/Spinner/Spinner';

import style from './StocklistReport.module.scss';
import StocklistForm from './StocklistForm';
import ReportTable from './ReportTable';

@observer
class StocklistReportPage extends Component {
  store = new StocklistReportStore();
  form = new StocklistReportForm();

  async componentDidMount() {
    await this.store.getReport(this.form.requestParams);
  }

  render() {
    const { report } = this.store;

    return (
      <section className={style.main}>
        {this.store.loading && <Spinner contain />}

        <StocklistForm form={this.form} store={this.store} />

        <ReportTable
          report={report}
          tableStyle={style.table__single}
          store={this.store}
        />
      </section>
    );
  }
}

export default StocklistReportPage;
