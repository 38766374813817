import React from 'react';
import { observer } from 'mobx-react';

import RootStore from 'stores/RootStore';
import StocklistStore from 'stores/Stocklist/StocklistStore';
import { FULL_DATE_FORMAT } from 'helpers/datetime';

import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';

import style from './Stocklist.module.scss';

interface Props {
  store: StocklistStore;
}

@observer
class StocklistHeader extends React.Component<Props> {
  render() {
    const { searchValue, activeDate, changeDate } = this.props.store;

    return (
      <div className={style.header}>
        <div className={style.header__date}>
          <Button
            styleType="text"
            onClick={this._handlePrevDayClick}
            className={style.header__arrowButton}
          >
            <Icon type="arrowLeft" />
          </Button>

          <Button
            styleType="text"
            onClick={this._handleNextDayClick}
            className={style.header__arrowButton}
          >
            <Icon type="arrowRight" />
          </Button>

          <Datepicker
            value={activeDate}
            onChange={changeDate}
            selectRange={false}
            showPeriods={false}
            styles={{
              button: style.header__datePicker__button,
            }}
            dateFormat={FULL_DATE_FORMAT}
            icon
            locale={RootStore.localization.locale}
          />
        </div>

        <div className={style.header__search__container}>
          <input
            type="text"
            placeholder={RootStore.localization.formatMessage(
              'SEARCH_PLACEHOLDER',
            )}
            value={searchValue}
            onChange={e => this._handleSearch(e.target.value)}
            className={style.header__searchInput}
          />
        </div>
      </div>
    );
  }

  private _handleSearch = (value: string) => {
    this.props.store.changeSearch(value);
  };

  private _handlePrevDayClick = () => {
    this.props.store.swipeDay('previous');
  };

  private _handleNextDayClick = () => {
    this.props.store.swipeDay('next');
  };
}
export default StocklistHeader;
