import { PLUItemComponent } from 'http/Api/PLUItems/types';

export const getPLUItemComponentOnCopy = (
  itemComponent: PLUItemComponent,
  selectedComponent: PLUItemComponent,
) => {
  switch (selectedComponent) {
    case PLUItemComponent.Consumer:
      return PLUItemComponent.Consumer;
    case PLUItemComponent.Spt:
      return PLUItemComponent.Spt;
    default:
      return itemComponent;
  }
};
