import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getDateTimeWithLocale } from 'helpers/datetime';
import PLUPageStore, { PLUCopyModeType } from 'stores/Settings/PLUPageStore';
import ModalStore from 'stores/ModalStore';

import Icon from 'components/Icon/Icon';
import PLUCellModal from 'components/Modals/PLUCellModal/PLUCellModal';

import PLUPlannerRow from './PLUPlannerRow';
import style from './PLUPlanner.module.scss';
import { action, observable } from 'mobx';
import { CopyPLUFromDayForm } from 'stores/Forms/Settings/CopyMode/CopyPLUFromDayForm';

interface Props {
  store: PLUPageStore;
}

@observer
class PLUPlannerGrid extends Component<Props> {
  public plannerRef = React.createRef<HTMLDivElement>();

  @observable private _plannerHeight = 0;

  componentDidMount() {
    this._calculatePlannerHeight();
  }

  componentDidUpdate() {
    this._calculatePlannerHeight();
  }

  render() {
    const { store } = this.props;

    return (
      <>
        <div className={style.planner} ref={this.plannerRef}>
          <div className={style.caption}>
            <div
              className={classNames(
                style.th,
                style.th__primary,
                style.th__cell,
              )}
            >
              <div className={style.caption__wrapper}>
                <FormattedMessage id="title.settings.plu.grid.cell" />

                <Icon
                  className={style.cell__icon}
                  type={'helpCircle'}
                  onClick={this._handleCellHelpIconClick}
                />
              </div>
            </div>

            <div
              className={classNames(
                style.th,
                style.th__primary,
                style.th__timeline,
              )}
            >
              <div className={style.caption__wrapper}>
                <FormattedMessage id="title.settings.plu.grid.time" />
              </div>
            </div>

            {store.plannerStore.periodDates.map(date => {
              const dateTime = getDateTimeWithLocale(date);

              return (
                <div key={date} className={classNames(style.th, style.weekday)}>
                  <span>{dateTime.day}</span>
                  <span>{dateTime.weekdayShort}</span>

                  {store.isCopyModeEnabled &&
                    store.copyModeType === PLUCopyModeType.Day &&
                    this._renderSelectionMask(date)}
                </div>
              );
            })}
            <div className={classNames(style.th, style.th__hidden)} />
          </div>

          {this._renderRows()}
        </div>

        {this._renderNoData()}
      </>
    );
  }

  private _renderRows = () => {
    const { store } = this.props;
    const plannerStore = store.plannerStore;

    const rows: JSX.Element[] = [];

    for (const cell in plannerStore.pluItems) {
      if (
        !plannerStore.pluItems.hasOwnProperty(cell) ||
        !plannerStore.pluItems[cell].length
      )
        continue;

      rows.push(<PLUPlannerRow key={cell} cell={cell} store={store} />);
    }

    return rows;
  };

  private _renderNoData = () => {
    const store = this.props.store.plannerStore;

    for (const cell in store.pluItems) {
      if (!store.pluItems.hasOwnProperty(cell)) continue;
      if (store.pluItems[cell].length) return;
    }

    return (
      <div className={style.noDataText}>
        <FormattedMessage id="table.noDataText" />
      </div>
    );
  };

  private _renderSelectionMask = (date: string) => {
    const { store } = this.props;
    const form = store.copyModeForm as CopyPLUFromDayForm;

    const dateTime = getDateTimeWithLocale(date);

    return (
      <div
        className={classNames(style.selectionMask, style.selectionMaskDay, {
          [style.active]: form.from.toISODate() === date,
        })}
        style={{
          height: this._plannerHeight - 8 + 'px',
        }}
        onClick={() => form.setDateFrom(dateTime)}
      />
    );
  };

  private _handleCellHelpIconClick = async () => {
    await ModalStore.showModal(PLUCellModal, { readMode: true });
  };

  @action
  private _calculatePlannerHeight = () => {
    this._plannerHeight =
      this.plannerRef.current?.getBoundingClientRect().height ?? 0;
  };
}

export default PLUPlannerGrid;
