import React from 'react';
import { observer } from 'mobx-react';

import Table from 'components/Table/Table';

import StocklistStore from 'stores/Stocklist/StocklistStore';
import ModalStore from 'stores/ModalStore';

import StocklistAdditionalAmountModal from 'components/Modals/Stocklist/AdditionalAmount/StocklistAdditionalAmountModal';

import StocklistHeader from './StocklistHeader';
import config from './columnsConfig';
import style from './Stocklist.module.scss';
import StocklistDecreaseAmountModal from 'components/Modals/Stocklist/DecreaseAmount/StocklistDecreaseAmountModal';

@observer
class StocklistPage extends React.Component {
  store = new StocklistStore();

  async componentDidMount() {
    await this.store.init();
  }

  componentWillUnmount() {
    this.store.clearInterval();
  }

  render() {
    const { stocklistData, loading, isAdditionalAmountDisabled } = this.store;

    return (
      <>
        <div className={style.main}>
          <StocklistHeader store={this.store} />

          <Table
            data={stocklistData}
            columns={config({
              isAdditionalAmountDisabled,
              onAdditionalAmountClick: this._handleAdditionalAmountClick,
              onDecreaseAmountClick: this._handleDecreaseAmountClick,
            })}
            loading={loading}
          />
        </div>
      </>
    );
  }

  private _handleAdditionalAmountClick = async (id: string) => {
    await ModalStore.showModal(StocklistAdditionalAmountModal, {
      id,
      onChange: this.store.fetchStocklist,
    });
  };

  private _handleDecreaseAmountClick = async (
    id: string,
    additionalAmount: number,
  ) => {
    await ModalStore.showModal(StocklistDecreaseAmountModal, {
      id,
      additionalAmount,
      onChange: this.store.fetchStocklist,
    });
  };
}
export default StocklistPage;
