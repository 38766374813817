import { action, observable } from 'mobx';

import {
  BaseFormModel,
  displayName,
  isNumberMoreOrEqual,
  isRequired,
} from '../../BaseForm';
import { StocklistDecreaseAmountDescriptionType } from './types';
import RootStore from 'stores/RootStore';

class StocklistDecreaseAmountModalForm extends BaseFormModel {
  constructor(additionalAmount: number) {
    super();

    this.amount = additionalAmount.toString();
  }

  @observable
  @isRequired()
  @isNumberMoreOrEqual(0)
  amount: string;

  @observable
  @isRequired()
  @displayName('modal.stocklist.decreaseAmount.descriptionType')
  descriptionType: StocklistDecreaseAmountDescriptionType | null = null;

  @observable
  @isRequired()
  description = '';

  @observable
  isEditMode = false;

  @action
  changeDescriptionType = (
    descriptionType: StocklistDecreaseAmountDescriptionType,
  ) => {
    this.descriptionType = descriptionType;

    switch (descriptionType) {
      case StocklistDecreaseAmountDescriptionType.Error: {
        this.description = RootStore.localization.formatMessage(
          'modal.stocklist.decreaseAmount.descriptionType.error',
        );
        return;
      }
      case StocklistDecreaseAmountDescriptionType.Break: {
        this.description = RootStore.localization.formatMessage(
          'modal.stocklist.decreaseAmount.descriptionType.break',
        );
        return;
      }
      case StocklistDecreaseAmountDescriptionType.Custom: {
        this.description = '';
        return;
      }
      default:
        return;
    }
  };

  @action
  changeIsEditMode = (isEditMode: boolean) => {
    this.isEditMode = isEditMode;
  };
}

export default StocklistDecreaseAmountModalForm;
