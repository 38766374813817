import React, { Component } from 'react';
import { Switch } from 'react-router';
import { observer } from 'mobx-react';

import style from './Layout.module.scss';

import AsideMenu from 'components/AsideMenu/AsideMenu';

import { Roles } from 'helpers/roles';

import PrivateRouter from 'routes/PrivateRouter';
import { ROUTES } from 'routes/routes';

import EmployeesList from 'pages/Employees/EmployeesList';
import EmployeeProfile from 'pages/Profile/EmployeeProfile';
import CategoriesPage from 'pages/Categories/CategoriesPage';
import StaffPage from 'pages/Staff/StaffPage';
import DishPage from 'pages/DishPage/DishPage';
import MenuPage from 'pages/MenuPlanner/MenuPage';
import RecognitionPage from 'pages/Recognition/RecognitionPage';
import DatasetPage from 'pages/Dataset/DatasetPage';
import SubsidiesPage from 'pages/Subsidies/SubsidiesPage';
import ReportPage from 'pages/Report/ReportPage';
import TaskPage from 'pages/Recognition/Task/TaskPage';
import ArchivePage from 'pages/Archive/ArchivePage';
import SubsidyPage from 'pages/Subsidies/Subsidy/SubsidyPage';
import SettingsPage from 'pages/Settings/SettingsPage';
import PLUItem from 'pages/Settings/PLUItem/PLUItem';

@observer
class BasicLayout extends Component {
  render() {
    return (
      <div className={style.layout}>
        <AsideMenu />

        <Switch>
          <PrivateRouter
            path={ROUTES.employees}
            roles={[Roles.hostess, Roles.accountant]}
            component={EmployeesList}
            exact
          />

          <PrivateRouter
            path={ROUTES.employeeProfile}
            roles={[Roles.hostess, Roles.accountant]}
            component={EmployeeProfile}
            exact
          />

          <PrivateRouter
            path={ROUTES.archiveAdmin.archive}
            roles={[Roles.archiveAdmin]}
            component={ArchivePage}
          />

          <PrivateRouter
            path={ROUTES.admin.report}
            roles={[
              Roles.admin,
              Roles.cateringAdmin,
              Roles.bayerRestrictedAdmin,
            ]}
            component={ReportPage}
          />

          <PrivateRouter
            path={[ROUTES.admin.staff]}
            roles={[
              Roles.admin,
              Roles.cateringAdmin,
              Roles.bayerRestrictedAdmin,
            ]}
            component={StaffPage}
            exact
          />

          <PrivateRouter
            path={ROUTES.admin.menuItems}
            component={CategoriesPage}
            exact
          />

          <PrivateRouter path={ROUTES.admin.menuItem} component={DishPage} />

          <PrivateRouter
            path={[ROUTES.admin.menu, ROUTES.admin.menuLines]}
            roles={[
              Roles.admin,
              Roles.cateringAdmin,
              Roles.bayerRestrictedAdmin,
            ]}
            component={MenuPage}
            exact
          />

          <PrivateRouter
            path={[
              ROUTES.admin.discounts,
              ROUTES.admin.staffGroups,
              ROUTES.admin.campaign,
            ]}
            roles={[
              Roles.admin,
              Roles.cateringAdmin,
              Roles.bayerRestrictedAdmin,
            ]}
            component={SubsidiesPage}
            exact
          />

          <PrivateRouter
            path={ROUTES.admin.discountItem}
            roles={[
              Roles.admin,
              Roles.cateringAdmin,
              Roles.bayerRestrictedAdmin,
            ]}
            component={SubsidyPage}
          />

          <PrivateRouter
            path={ROUTES.admin.dataset}
            roles={[Roles.admin, Roles.bayerRestrictedAdmin]}
            component={DatasetPage}
            exact
          />

          <PrivateRouter path={ROUTES.admin.task} component={TaskPage} />

          <PrivateRouter
            path={ROUTES.admin.recognition}
            roles={[Roles.admin, Roles.bayerRestrictedAdmin]}
            component={RecognitionPage}
          />

          <PrivateRouter
            path={ROUTES.admin.settings}
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            component={SettingsPage}
            exact
          />
          <PrivateRouter
            path={[
              ROUTES.admin.settingsPluItem,
              ROUTES.admin.settingsPluItemNew,
            ]}
            roles={[
              Roles.admin,
              Roles.bayerRestrictedAdmin,
              Roles.cateringAdmin,
            ]}
            component={PLUItem}
          />
        </Switch>
      </div>
    );
  }
}

export default BasicLayout;
