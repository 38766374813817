import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import { ModalProps } from 'stores/ModalStore';
import StocklistAdditionalAmountModalStore from 'stores/Stocklist/StocklistAdditionalAmountModalStore';

import Button from 'components/Button/Button';
import TextField from 'components/Form/Fields/Text/TextField';
import { FormControl } from 'components/Form/FormControl/FormControl';
import ModalHeader from 'components/Modals/Components/ModalHeader/ModalHeader';
import ModalBody from 'components/Modals/Components/ModalBody/ModalBody';
import ModalControls from 'components/Modals/Components/ModalControls/ModalControls';

import style from './StocklistAdditionalAmountModal.module.scss';

interface Props extends ModalProps {
  id: string;
  onChange?: () => void;
}

@observer
class StocklistAdditionalAmountModal extends Component<Props> {
  private _store = new StocklistAdditionalAmountModalStore(this.props.id);

  render() {
    return (
      <Modal
        {...this.props}
        className={style.modal}
        destroyOnClose={true}
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <ModalHeader>
          <FormattedMessage id="modal.stocklist.additionalAmount.title" />
        </ModalHeader>

        <ModalBody>
          <div className={style.form__field}>
            <FormControl
              form={this._store.form}
              name="amount"
              render={propsField => <TextField {...propsField} />}
            />
          </div>
        </ModalBody>

        <ModalControls>
          <>
            <Button
              className={style.btn}
              styleType="ghost"
              onClick={this._handleClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={style.btn}
              styleType="primary"
              disabled={this._store.isDisabled || this._store.loading}
              onClick={this._handleSubmit}
            >
              <FormattedMessage id="button.saveChanges" />
            </Button>
          </>
        </ModalControls>
      </Modal>
    );
  }

  private _handleClose = () => {
    this.props.onClose();
  };

  private _handleSubmit = async () => {
    const { onChange, onClose } = this.props;

    await this._store.submit();

    onChange?.();
    onClose();
  };
}

export default StocklistAdditionalAmountModal;
