import React, { Component } from 'react';
import Modal from 'rc-dialog';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import {
  OnlineOrderPlace,
  OnlineOrdersFilters,
  OnlineOrderStatus,
} from 'stores/OnlineOrders/type';
import RootStore from 'stores/RootStore';

import SelectField, { Option } from 'components/Form/Fields/Select/SelectField';
import Button from 'components/Button/Button';

import './OnlineOrderFilterModal.scss';
import ModalHeader from '../Components/ModalHeader/ModalHeader';
import ModalBody from '../Components/ModalBody/ModalBody';
import ModalControls from '../Components/ModalControls/ModalControls';

interface IProps {
  filters: OnlineOrdersFilters;
  orderLocations: Array<Option<string>>;
  onApplyFilter: (filters: OnlineOrdersFilters) => void;
  onClose: () => void;
}

interface IState {
  selectedPlaces: string[];
  selectedStatuses: string[];
}

@observer
class FilterModal extends Component<IProps, IState> {
  state = {
    selectedPlaces: this.props.filters.places || [],
    selectedStatuses: this.props.filters.statuses || [],
  };

  private _statusOptions: Array<Option<string>> = [
    {
      value: OnlineOrderStatus.ALL,
      label: RootStore.localization.formatMessage(
        'modal.online.orders.filter.status.all',
      ),
    },
    {
      value: OnlineOrderStatus.DELIVERED,
      label: RootStore.localization.formatMessage('title.order.control.status'),
    },
    {
      value: OnlineOrderStatus.PENDING,
      label: RootStore.localization.formatMessage(
        'modal.online.orders.filter.status.pending',
      ),
    },
  ];

  render() {
    const isApplyButtonDisabled =
      !this.state.selectedPlaces.length || !this.state.selectedStatuses.length;

    return (
      <Modal
        className={'filterModal'}
        onClose={this.props.onClose}
        destroyOnClose
        animation="zoom"
        maskAnimation="fade"
        visible
      >
        <ModalHeader>
          <FormattedMessage id={'modal.online.orders.filter.title'} />
        </ModalHeader>
        <ModalBody>
          <>
            <div className={'filterModal__field'}>
              <label>
                <FormattedMessage id="modal.online.orders.filter.label.location" />
              </label>
              <SelectField<string>
                value={this.state.selectedPlaces}
                onChange={this._handlePlaceChange}
                options={this._placeOptions}
                isMulti
              />
            </div>

            <div className={'filterModal__field'}>
              <label>
                <FormattedMessage id="modal.online.orders.filter.label.status" />
              </label>
              <SelectField<string>
                value={this.state.selectedStatuses}
                onChange={this._handleStatusChange}
                options={this._statusOptions}
                isMulti
              />
            </div>
          </>
        </ModalBody>
        <ModalControls>
          <>
            <Button
              className={'filterModal__button'}
              styleType="ghost"
              onClick={this.props.onClose}
            >
              <FormattedMessage id="button.cancel" />
            </Button>

            <Button
              className={'filterModal__button'}
              type="submit"
              onClick={this._applyFilter}
              disabled={isApplyButtonDisabled}
            >
              <FormattedMessage id="button.filter" />
            </Button>
          </>
        </ModalControls>
      </Modal>
    );
  }

  private _handlePlaceChange = (
    values: string | string[] | null | undefined,
  ) => {
    this.setState({ selectedPlaces: Array.isArray(values) ? values : [] });
  };

  private _handleStatusChange = (
    values: string | string[] | null | undefined,
  ) => {
    this.setState({ selectedStatuses: Array.isArray(values) ? values : [] });
  };

  private _applyFilter = () => {
    const { selectedPlaces, selectedStatuses } = this.state;
    this.props.onApplyFilter({
      places: selectedPlaces,
      statuses: selectedStatuses,
    });
    this.props.onClose();
  };

  private get _placeOptions() {
    const options: Array<Option<string>> = [
      {
        value: OnlineOrderPlace.ALL,
        label: RootStore.localization.formatMessage('title.order.places.all'),
      },
      {
        value: OnlineOrderPlace.TAKE_AWAY,
        label: RootStore.localization.formatMessage('PICKUP'),
      },
      {
        value: OnlineOrderPlace.IN_PLACE,
        label: RootStore.localization.formatMessage(
          'modal.online.orders.filter.label.inplace',
        ),
      },
    ];

    const { feature } = RootStore.config;

    const isDeliveryEnabled =
      feature.delivery?.enabled &&
      (feature.deliveryServerLocations?.enabled ||
        feature.deliveryCustomLocations?.enabled);

    if (isDeliveryEnabled) {
      options.push({
        value: OnlineOrderPlace.DELIVERY,
        label: RootStore.localization.formatMessage('DELIVERY'),
      });
    }

    return options;
  }
}

export default FilterModal;
