import { computed, observable } from 'mobx';

import StocklistDecreaseAmountModalForm from 'stores/Forms/Stocklist/StocklistDecreaseAmountModalForm';
import { PromiseService } from 'services/PromiseService';
import Log from 'helpers/log';
import httpFacade from 'http/httpFacade';
import { StocklistDecreaseAmountDescriptionType } from 'stores/Forms/Stocklist/types';
import RootStore from 'stores/RootStore';

class StocklistDecreaseAmountModalStore {
  @observable public form: StocklistDecreaseAmountModalForm;

  @observable private _promiseService = new PromiseService();
  private _id: string;
  private _initAdditionalAmount: number;

  constructor(id: string, additionalAmount: number) {
    this._id = id;
    this._initAdditionalAmount = additionalAmount;

    this.form = new StocklistDecreaseAmountModalForm(additionalAmount);
  }

  public submit = async () => {
    if (this.isDisabled || this.loading) return;

    try {
      const decreaseValue = (
        this._initAdditionalAmount - +this.form.amount
      ).toString();

      await this._promiseService.add(() =>
        httpFacade.stocklist.decreaseAmount(
          this._id,
          decreaseValue,
          this.form.description,
        ),
      );
    } catch (error) {
      Log.info(error);
    }
  };

  @computed
  public get isDisabled() {
    return (
      this.form.invalidFields.length ||
      +this.form.amount > this._initAdditionalAmount
    );
  }

  @computed
  public get loading() {
    return this._promiseService.isPending;
  }

  @computed
  public get descriptionTypeOptions(): Array<{
    label: string;
    value: StocklistDecreaseAmountDescriptionType;
  }> {
    return [
      {
        label: RootStore.localization.formatMessage(
          'modal.stocklist.decreaseAmount.descriptionType.error',
        ),
        value: StocklistDecreaseAmountDescriptionType.Error,
      },
      {
        label: RootStore.localization.formatMessage(
          'modal.stocklist.decreaseAmount.descriptionType.break',
        ),
        value: StocklistDecreaseAmountDescriptionType.Break,
      },
      {
        label: RootStore.localization.formatMessage(
          'modal.stocklist.decreaseAmount.descriptionType.custom',
        ),
        value: StocklistDecreaseAmountDescriptionType.Custom,
      },
    ];
  }
}

export default StocklistDecreaseAmountModalStore;
