import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { SortingRule } from 'react-table';

import { IStocklistReport } from 'stores/Report/types';
import StocklistReportStore from 'stores/Report/StocklistReportStore';

import Table from 'components/Table/Table';

import config from './columnsConfig';
import style from './StocklistReport.module.scss';

interface Props {
  report: IStocklistReport[];
  tableStyle?: string;
  store?: StocklistReportStore;
}

@observer
class ReportTable extends Component<Props> {
  @observable styles = new Map();
  private _defaultSorted = [{ id: 'date', desc: true }];

  onResize = newResized => {
    newResized.forEach(it => {
      this.styles.set(it.id, it.value);
    });
  };

  onSort = (newSorted: SortingRule[], column: any, additive: boolean): void => {
    if (newSorted.length) {
      this.props.store?.changeSortProperty({
        id: newSorted[0].id,
        desc: newSorted[0].desc,
      });
    } else {
      this.props.store?.changeSortProperty(null);
    }
  };

  render() {
    const { report, tableStyle } = this.props;

    return (
      <>
        <div className={style.table__wrapper}>
          <Table
            data={report}
            tableClassName={style.reportTable}
            defaultSorted={this._defaultSorted}
            className={classNames(style.table, tableStyle)}
            columns={config()}
            onResizedChange={this.onResize}
            onSortedChange={this.onSort}
          />
        </div>
      </>
    );
  }
}

export default ReportTable;
