import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { DateTime } from 'luxon';
import { PLUItemComponent } from 'http/Api/PLUItems/types';
import { isAfterDate } from 'helpers/datetime';

import Button from 'components/Button/Button';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import DaysMultiSelect from 'components/MultiSelect/DaysMultiSelect/DaysMultiSelect';
import SoftwareComponentSelect from 'components/Form/Fields/Select/SoftwareComponentSelect/SoftwareComponentSelect';

import style from './CopyMode.module.scss';

interface IProps {
  dateFrom: DateTime;
  periodTo: [DateTime, DateTime];
  component: PLUItemComponent;
  disabled?: boolean;
  days: string[];
  onDateFromChange: (date: DateTime) => void;
  onPeriodToChange: (dates: DateTime[]) => void;
  onDaysChange: (value: string[]) => void;
  onComponentChange: (component: PLUItemComponent | null) => void;
  onSubmit: () => void;
}

class CopyFromDayComponent extends Component<IProps> {
  render() {
    const {
      dateFrom,
      periodTo,
      days,
      component,
      disabled,
      onDateFromChange,
      onPeriodToChange,
      onDaysChange,
      onComponentChange,
      onSubmit,
    } = this.props;

    return (
      <>
        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.from" />
          </div>
          <Datepicker
            value={dateFrom}
            onChange={date => onDateFromChange((date as unknown) as DateTime)}
            selectRange={false}
            showPeriods={false}
            icon
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.toPeriod" />
          </div>
          <Datepicker
            value={periodTo}
            showPeriods={false}
            selectRange
            icon
            styles={{
              calendar: style.datepicker,
              button: style.datepickerButton,
              icon: style.datepickerIcon,
            }}
            isDateDisabled={this._isPeriodDayDisabled}
            onChange={onPeriodToChange}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.copy" />
          </div>
          <DaysMultiSelect
            value={days}
            fieldClassName={style.multiSelect}
            onChange={onDaysChange}
          />
        </div>

        <div className={style.controlWrapper}>
          <div className={style.controlLabel}>
            <FormattedMessage id="copyMode.label.component" />
          </div>
          <SoftwareComponentSelect
            value={component}
            className={style.select}
            onChange={onComponentChange}
          />
        </div>

        <Button
          className={style.btnCopy}
          disabled={disabled}
          onClick={onSubmit}
        >
          <FormattedMessage id="button.copy" />
        </Button>
      </>
    );
  }

  private _isPeriodDayDisabled = (date: Date) => {
    return !isAfterDate(date, new Date());
  };
}

export { CopyFromDayComponent };
