import React, { Component } from 'react';

import RootStore from 'stores/RootStore';
import { PLUItemComponent } from 'http/Api/PLUItems/types';

import SelectField, { SelectFieldProps } from '../SelectField';

interface Props extends Omit<SelectFieldProps<PLUItemComponent>, 'options'> {}

class SoftwareComponentSelect extends Component<Props> {
  render() {
    return <SelectField {...this.props} options={this.options} />;
  }

  public get options() {
    const options = Object.values(PLUItemComponent);

    return options.map(option => {
      const label = RootStore.localization.formatMessage(
        `select.plu.component.${option}`,
      );

      return {
        value: option,
        label,
      };
    });
  }
}

export default SoftwareComponentSelect;
