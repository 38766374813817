import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import { MenuItem } from 'stores/Menu/types';
import { FormattedMessage } from 'react-intl';

import style from './Planner.module.scss';

import { MenuPageStore } from 'stores/Menu/MenuPageStore';
import { CopyMode } from 'stores/Menu/MenuCopy/types';

import Icon from 'components/Icon/Icon';
import FormattedCurrency from 'components/FormattedCurrency/FormattedCurrency';

interface Props {
  date: string;
  disabled?: boolean;
  onClick: () => void;
  items: MenuItem[];
  menuPage?: MenuPageStore;
  lineId: string;
}

@inject('menuPage')
@observer
class PlannerWeekday extends Component<Props> {
  render() {
    const { items, disabled, onClick, menuPage, lineId } = this.props;
    const isEmpty = !Boolean(items.length);

    return (
      <div
        className={classNames(style.td, style.day, {
          [style.day__empty]: isEmpty,
          [style.day__disabled]: disabled && !menuPage?.isCopyMode,
          [style.day__copy]: menuPage?.isCopyMode,
        })}
        onClick={disabled || menuPage?.isCopyMode ? undefined : onClick}
        role="button"
      >
        {isEmpty ? (
          <Icon type="plus" className={style.icon__plus} />
        ) : (
          items.map(item => (
            <div className={style.menu__item} key={item.id}>
              {menuPage?.isCopyMode &&
                menuPage.copyMenu?.copyMode === CopyMode.MENU_ITEMS && (
                  <div
                    onClick={menuPage.copyMenu.togglePlannerMenuItem({
                      lineId,
                      item,
                    })}
                    className={classNames(
                      style.selectionMask,
                      style.selectionMaskMenuItem,
                      {
                        [style.active]: menuPage.copyMenu.isPlannerItemSelected(
                          lineId,
                          item.id,
                        ),
                      },
                    )}
                  />
                )}
              <div className={style.item__header}>
                <div className={style.item__title}>{item.title}</div>
                {(item.initStock ?? 0) > 0 && (
                  <div className={style.item__amount}>x{item.initStock}</div>
                )}
              </div>
              <div className={style.item__description}>{item.description}</div>
              <div className={style.item__data}>
                <FormattedCurrency value={item.price} />

                <ul className={style.item__allergens}>
                  {item.allergens.map(it => (
                    <li key={it.id}>
                      <img
                        className={style.allergen__icon}
                        src={it.icon}
                        alt="allergen"
                      />
                    </li>
                  ))}
                </ul>
              </div>

              <div className={style.item__ingredients}>
                {item?.ingredients &&
                  Object.entries?.(item?.ingredients)?.map(([key, value]) => (
                    <div className={style.item__ingredient} key={key}>
                      <FormattedMessage
                        id={key.toUpperCase()}
                        defaultMessage={key}
                      />{' '}
                      {value}
                    </div>
                  ))}
              </div>
            </div>
          ))
        )}
      </div>
    );
  }
}

export default PlannerWeekday;
