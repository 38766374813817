import { action, computed, observable } from 'mobx';

import Localization from './Localization';
import UserStore from './UserStore/UserStore';

import httpFacade from 'http/httpFacade';

import Log from 'helpers/log';

import SocketStore from './Socket/Socket';
import SampleLoaderService from './Services/SampleLoaderService';
import AppRouter from './AppRouter';

import { ROUTES } from 'routes/routes';
import { DeliveryOptions } from './UserProfile/types';
import { PaymentType } from './Recharge/types';
import { mergeObjects } from '../helpers/mergeObjects';

export enum AppFeatures {
  sionumSpeedy = 'sionumSpeedy',
  feedback = 'feedback',

  delivery = 'delivery',
  deliveryTimeSlots = 'deliveryTimeSlots',
  deliveryServerLocations = 'deliveryServerLocations',
  deliveryCustomLocations = 'deliveryCustomLocations',
  deliveryInPlace = 'deliveryInPlace',
  deliveryPickup = 'deliveryPickup',
  deliveryOptions = 'deliveryOptions',
  subsidies = 'subsidies',
}

interface Config {
  nfc: string;
  rfid: string;
  help: string;
  feedback: string;
  rules: string;
  dsvgo: string;
  problemsInstruction: string;
  payment: {
    negativeBalanceLimit: {
      amount: string;
    };
  };
  notification: {
    hr: string;
    mngr: string;
  };
  registration: {
    welcome: {
      title: string;
      text: string;
    };
  };
  order: {
    startDate: {
      daysOffset: string;
    };
  };
  feature: Record<AppFeatures, { enabled: boolean } | DeliveryOptions | any>;
  paymentType: Record<PaymentType, { enabled: boolean }>;
  online: {
    buyer: {
      consumption: {
        condition: {
          default: string;
        };
      };
    };
  };
}

class RootStore {
  @observable config: Config;

  @observable localization = new Localization();
  @observable socket = new SocketStore();
  @observable user = new UserStore();

  @observable sampleLoaderService = new SampleLoaderService();

  @observable loading = true;
  @observable serverError = false;
  @observable notFoundError = false;

  @computed
  get hasErrors() {
    return this.serverError || this.notFoundError;
  }

  @action.bound
  async init() {
    try {
      this.loading = true;

      const {
        data: defaultConfig,
      } = await httpFacade.root.fetchDefaultConfig();
      const { locale, fetchTranslation } = this.localization;

      this.config = defaultConfig.ui as any;

      await this.fetchConfig();

      await fetchTranslation(locale);

      this.user.init();

      // @ts-ignore
      window.toggleFeature = this.toggleFeature;

      this.loading = false;
    } catch (error) {
      Log.info('init error');
    }
  }

  @action.bound
  async fetchConfig() {
    try {
      const { data } = await httpFacade.root.fetchConfig();

      if ((data as any) && data?.length) {
        const serverConfig = data.reduce((acc, configResponse) => {
          const paramKey = configResponse.paramKey.replace('ui.', '');
          const paramValue =
            paramKey.includes('enabled') || paramKey.includes('bayerSpecific')
              ? JSON.parse(configResponse.paramValue)
              : configResponse.paramValue;

          const keys = paramKey.split('.');
          let cursor = acc;

          keys.forEach((key, index) => {
            if (!cursor[key]) {
              cursor[key] = index === keys.length - 1 ? paramValue : {};
            }
            cursor = cursor[key];
          });

          return acc;
        }, {});

        this.config = mergeObjects(this.config, serverConfig);
      } else {
        return;
      }
    } catch (error) {
      Log.error(error);
    }
  }

  @action.bound
  resetErrors() {
    this.notFoundError = false;
    this.serverError = false;

    AppRouter.push(ROUTES.root);
  }

  @action.bound
  toggleFeature(feature: AppFeatures, options?: DeliveryOptions) {
    if (options) {
      this.config.feature[feature] = options;
    }
    this.config.feature[feature].enabled = !this.config.feature[feature];
  }
}

export default new RootStore();
